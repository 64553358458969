import { observable, computed, reaction } from 'mobx';
import * as firebase from 'firebase/app';
import "firebase/firestore";
import copyFromRemoteData from '../utils/copyFromRemoteData';

const SHOULD_AUTO_LISTEN_TO_ON_SNAPSHOT = false;

const CDN_PATH = 'https://storage.googleapis.com/styld-cdn/partners';
const THUMBS = {
  xs: 100,
  s: 400,
  m: 800,
  b: 1200,
};

class Product {
  id = null;
  static _all = {};
  static get all() {
    return this._all;
  }
  static path({id, partnerId, gender}) {
    return 'partners/'+partnerId+'/products_'+gender+'/'+id;
  }
  // static find({id, partnerId, gender}) {
  //   var id = this.path({id, partnerId, gender});
  //   return this.all[id];
  // }
  static find(id) {
    return this.all[id];
  }
  static fetchRef = async (ref) => {
    var doc = await ref.get();
    return new Product(doc);
  }
  static fetch = async ({id, partnerId, gender, brand, title}) => {
    //id = null;
    //brand = 'THOM BROWNE';
    //title = 'Grosgrain Cotton Oxford Shirt';
    if (id && partnerId && gender) {
      var path = this.path({id, partnerId, gender});
      try {
      var doc = await firebase.firestore().doc(path).get(); 
      } catch(e) {
        console.log('ERROR', e.message)
        console.log('PATH', path)
      } 
      return new Product(doc);
    } else {
      try {
        var doc = await (
          firebase.firestore()
          .collectionGroup('products_' + gender)
          .where('brand', '==', brand)
          .where('title', '==', title)
          .get()
        );
        doc = doc.docs[0];
      } catch (e) {
        console.log('OY:', e.message);
        console.log('OY::', gender, brand, title);
      }
      return new Product(doc);
    }
  }
  
  constructor(doc) {
    var {
      id, 
      //program_name,
      //gender,
    } = doc;
    //id = Product.path({id, partnerId: program_name, gender});
    this.id = id;
    this.ref = doc.ref;
    this.constructor.all[id] = this;
    copyFromRemoteData(doc, this);
    SHOULD_AUTO_LISTEN_TO_ON_SNAPSHOT && this.ref.onSnapshot(doc => {
      copyFromRemoteData(doc, this);
    });  
  }

  getThumb(_size = 'm') {
    if (this.hasThumbs) {
      var size = THUMBS[_size];
      return (
        CDN_PATH + '/'
        + this.program_name + '/' 
        + this.gender + '/' 
        + this.id + '/' 
        + '__' + size + 'x' + size + '.jpg'
      );
    } else {
      return this.image_link;
    }
  }
  @computed get thumbMedium() {
    return this.getThumb('m')
  }
  @computed get thumbSmall() {
    return this.getThumb('s')
  }

}

export default Product;