import { YellowBox } from 'react-native';
YellowBox.ignoreWarnings(['Setting a timer']);
import './utils/first-input-delay'; // for firebase performance
import * as React from 'react';
import { HelmetProvider } from './utils/Helmet';

//https://stackoverflow.com/questions/60361519/cant-find-a-variable-atob
// import {decode, encode} from 'base-64'
// if (!global.btoa) {  global.btoa = encode }
// if (!global.atob) { global.atob = decode }


import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { SplashScreen } from 'expo';
import { 
  NavigationContainer, 
  DefaultTheme, 
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import NavigationComponent from './navigation';
import useLinking from './navigation/useLinking';
import Initializer from "./initializers";


// Need to force hebrew android to be LTR
import { I18nManager } from "react-native";  
import Device from './utils/Device';
import css from './utils/css';
import Analytics from './services/Analytics';
I18nManager.forceRTL(false);
I18nManager.allowRTL(false);


const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#ffffff',
  },
};

const Stack = createStackNavigator();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const navigationRef = React.useRef();
  const routeNameRef = React.useRef();
  const { getInitialState } = useLinking(navigationRef);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        await Initializer.init();

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <HelmetProvider>
      <ActionSheetProvider>
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <NavigationContainer 
          initialState={initialNavigationState} 
          theme={MyTheme}
          ref={navigationRef}
          onReady={() => routeNameRef.current = navigationRef.current.getCurrentRoute().name}
          onStateChange={() => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.current.getCurrentRoute().name
            if (previousRouteName !== currentRouteName) {
              // The line below uses the expo-firebase-analytics tracker
              // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
              // Change this line to use another Mobile analytics SDK
              Analytics.setCurrentScreen(currentRouteName);
            }

            // Save the current route name for later comparision
            routeNameRef.current = currentRouteName;
          }}
        >
          <Stack.Navigator headerMode="none">
            <Stack.Screen name="STYLD" component={NavigationComponent} />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
      </ActionSheetProvider>
      </HelmetProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});

if (Device.isDesktop) {
  css(`
    ::-webkit-scrollbar {
      display: none;
    }
    .productBuyLink {
      display: block !important
    }
  `)
}