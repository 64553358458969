import * as Updates from 'expo-updates';
import {
  Alert,
} from 'react-native';
import { AppState as AppStateNative } from 'react-native';
import Device from '../utils/Device';

const initOta = async () => {
  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      // ... notify user of update ...
      Alert.alert(
        'New version is ready!',
        'Press ok button',
        [
          { 
            text: 'OK', 
            onPress: () => {
              AppStateNative.removeEventListener('change', handler);  
              Updates.reloadAsync()
            },
          },
        ],
        { cancelable: false }
      );
    }
  } catch (e) {
    // handle or log error
  }
}

const handler = nextAppState => {
  if (nextAppState === 'active') {
    initOta();
  }
};

if (Device.isMobileApp) {
  AppStateNative.addEventListener('change', handler);
  initOta();
};