import React from 'react';
import { View,
  StyleSheet,
  Text,
  StatusBar,
} from 'react-native';
import Button from '../components/Button';
import Fonts from '../constants/Fonts';
import Sizes from '../constants/Sizes';
import Device from '../utils/Device';
import px from '../utils/px';
import UserIcon from '@expo/vector-icons/FontAwesome';
import Constants from "expo-constants"

export default class HeaderMobile extends React.Component {
  render () {
    const {
      search = true,
      profile = true,
      back = false,
      navigation,
    } = this.props;
    return (
      <View style={styles.headerContainer}>
        <StatusBar hidden />
        {search &&
        <Button
          //iconComponent={UserIcon}
          icon="search1"
          transparent
          whiteText
          square
          style={styles.searchButton}
          iconStyle={styles.searchIcon}
          onPress={() => navigation.navigate('Search', {screen: 'Search'})}
          //text={'Search'}
        />
        }
        {back &&
        <Button
          iconComponent={UserIcon}
          icon="chevron-left"
          transparent
          whiteText
          square
          style={styles.backButton}
          iconStyle={styles.searchIcon}
          onPress={() => navigation.goBack()}
          //text={'Search'}
        />
        }
        <Text style={styles.logoText} onPress={() => navigation.navigate('Home', {screen: 'Home'})}>STYLD</Text>
        {profile && 
        <Button
          icon="user-o"
          iconComponent={UserIcon}
          transparent
          whiteText
          square
          style={styles.searchButton}
          iconStyle={styles.userIcon}
          onPress={() => navigation.navigate('Me', {screen: 'Me'})}
          //text={'Search'}
        />
        }
      </View>
    )
  }
}

var styles = StyleSheet.create({
  headerContainer: {
    //paddingTop: Constants.statusBarHeight,
    height: Sizes.LAYOUT_ITEM,
    width: Device.width,
    backgroundColor: '#000000',
    flexDirection: 'row',
    alignItems: 'center'
  },
  logoText: {
    fontFamily: Fonts.LOGO_BOLD,
    fontSize: Device.isMobileApp ? px(18) : px(28),
    color: '#FFFFFF',
    lineHeight: px(24),
    flex: 1,
    textAlign: 'center',
    //marginRight: px(-Sizes.LAYOUT_ITEM)
  },
  searchIcon: {
    fontSize: Device.isMobileApp ? px(14) : px(22),
  },
  userIcon: {
    fontSize: Device.isMobileApp ? px(14) : px(22),
  },
  searchButton: {
    marginTop: px(4)
  },
  backButton: {
    marginTop: px(4),
    marginRight: -Sizes.LAYOUT_ITEM,
  }
})