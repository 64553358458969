import { observer } from 'mobx-react';
import React from 'react';
import { 
  StyleSheet, 
  View,
  Text,
} from 'react-native';
import Fonts from '../constants/Fonts';
import Sizes from '../constants/Sizes';
import Auth from '../services/Auth';
import Device from '../utils/Device';
import px from '../utils/px';

@observer
export default class UnreadNumber extends React.Component {
  render() {
    if(!Auth.isLoggedIn || !Auth.user) return null;
    var number = Auth.user.unreadSocial;
    if (!number) return null;
    if (number > 9) number = '9+';
    return (
      <View style={styles.container}>
        <Text style={styles.number}>{number}</Text>
      </View>
    )
  }
}

const mobile = {
  position: 'absolute',
  right: 0,
  borderWidth: 1,
  borderColor: '#ffffff',
  borderStyle: 'solid',
  zIndex: 100,
  marginBottom: -px(12),
  alignSelf: 'flex-end',
  marginRight: -Sizes.MARGIN / 2
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#333333',
    alignItems: 'center',
    justifyContent: 'center',
    ...Device.select({
      mobile: {
        ...mobile,
        width: px(12),
        height: px(12),
        borderRadius: px(6),
        top: px(3),
      },
      mobileWeb: {
        ...mobile,
        width: px(16),
        height: px(16),
        borderRadius: px(8),
        top: px(6),
      },
      desktop: {
        width: px(14),
        height: px(14),
        borderRadius: px(7),
        marginLeft: px(-2),
        marginTop: px(-8)
      }
    })
  },
  number: {
    fontFamily: Fonts.MAIN,
    color: '#ffffff',
    ...Device.select({
      mobile: {
        fontSize: px(7),
        lineHeight: px(8),
      },
      desktop: {
        fontSize: px(9),
        lineHeight: px(10),
      },
      mobileWeb: {
        fontSize: px(10),
        lineHeight: px(11),
      },
    }),
  },
  
})