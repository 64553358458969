import { observable, computed } from 'mobx';
import { firestore } from 'firebase';
import Product from './Product';
import copyFromRemoteData from '../utils/copyFromRemoteData';

export const OUTFIT_PRIVACY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
}

const OUTFIT_FOLLOWERS_PER_PAGE = 20;

class Outfit {
  @observable ref = null;
  @observable products = [];
  @observable followers = [];
  @observable name = null;
  @observable description = null;
  @observable updated_at = null;
  @observable privacy = null;
  @observable followersCount = null;
  @observable outfitFollowersLoading = false;
  id = null;
  lastOutfitFollower = null;
  static _all = {};
  // static db = db;
  // static doc(id) {
  //   return db().doc(id);
  // }
  static get all() {
    return this._all;
  }
  static find(id) {
    return this.all[id];
  }
  static fetchRef = async(ref) => {
    var doc = await ref.get();
    return new Outfit(doc);
  }
  // copyFromRemoteData = (doc) => {
  //   Object.entries(doc.data()).forEach(([key, val]) => this[key] = val);
  // }
  constructor(doc) {
    this.id = doc.id;
    this.ref = doc.ref;
    this.constructor.all[doc.id] = this;
    copyFromRemoteData(doc, this);
    this.ref.onSnapshot(doc => {
      copyFromRemoteData(doc, this);
    });    
    this.listenForProductsChanges(doc);
  }
  listenForProductsChanges(doc) {
    doc.ref.collection('outfitProducts').onSnapshot(async snapshot => {
      var addedAt = {};
      var promises = snapshot.docs.map( productDoc => {        
        var product = Product.find(productDoc.id);
        if (!product) {
          product = Product.fetchRef(productDoc.data().productRef);
        }
        addedAt[productDoc.id] = productDoc.data().added_at;
        return product;
      });
      var docs = await Promise.all(promises);
      this.sortProductsByAddedAt(addedAt, docs);
      this.products.replace(docs);
    })
  }

  sortProductsByAddedAt (addedAt, docs) {
    docs.sort((a, b) => {
      if (!addedAt[a.id] || !addedAt[b.id]) return 0;
      var dateA = addedAt[a.id].toDate();
      var dateB = addedAt[b.id].toDate();
      return dateB - dateA;
    })
  }

  static template(user) {
    return {
      name: '',
      privacy: OUTFIT_PRIVACY.PUBLIC,
      description: null,
      authorName: (user && (user.username || user.name)) || '',
      authorRef: user.ref,
      authorId: user.id,
      created_at: firestore.FieldValue.serverTimestamp(),
      updated_at: firestore.FieldValue.serverTimestamp(),
    };
  }
  static updateOrCreate = async (wl, data, user) => {
    if (wl.id) {
      var obj = {};
      Object.keys(this.template(user)).forEach(key=>obj[key]=data[key]);
      // failed to do same with reduce:
      // Object.keys(this.template()).reduce((obj, key) => obj[key] = data[key] , {})
      return wl.ref.set(obj)
    } else {
      var ref = await user.outfitsCollection().add(data);
      var doc = await ref.get();
      return new Outfit(doc);
    }
  }

  static isAuthor(wl, user) {
    if (!user) return false;
    return wl.authorId == user.id;
  }

  static canEditOutfit(wl, user) {
    if (!user) return false;
    return this.isAuthor(wl, user);
  }
  static createDefaultOutfitForMe = (user) => {
    var t = this.template(user);
    t.name = 'My Outfit';
    return user.outfitsCollection().add(t);
  }
  static isProductInAnyOutfit =  (product, user) => {
    return user.outfits.filter(wl => this.isProductInOutfit(wl, product));
  }
  static isProductInOutfit = (outfit, product) => {
    var isIn = outfit.products.find(p => p.id == product.id);
    return isIn ? outfit : null;
  }

  static isPrivate(wl) {
    return wl.privacy == OUTFIT_PRIVACY.PRIVATE;
  }

  static addProductToOutfit(outfit, product, originOutfitId) {
    outfit.products && outfit.products.push(product); // optimistic , to light up star faster
    outfit.ref.collection('outfitProducts').doc(product.id).set({
      productRef: product.ref,
      outfitRef: outfit.ref,
      id: product.id,
      gender: product.gender,
      partnerId: product.program_name,
      added_at: firestore.FieldValue.serverTimestamp(),
      outfitPrivacy: outfit.privacy,
      outfitName: outfit.name,
      outfitId: outfit.id,
      authorRef: outfit.authorRef,
      authorId: outfit.authorId,
      authorName: outfit.authorName,
      originOutfitId: originOutfitId || null,
      originOutfitRef: originOutfitId ? Outfit.find(originOutfitId).ref : null,
    })
  }

  static removeProductFromOutfit(outfit, product) {
    outfit.ref.update({
      updated_at: firestore.FieldValue.serverTimestamp(),
    })
    outfit.ref.collection('outfitProducts').doc(product.id).delete();
  }

  static fetchFromRemote = async (outfitId, authorId) => {
    var db = firestore();
    var outfitRef = db.doc('users/' + authorId + '/outfits/' + outfitId);
    var doc = await outfitRef.get();
    if (doc.exists)
      return new Outfit(doc);
    return null;
  } 

  fetchOutfitFollowers = async () => {
    //var db = firebase.firestore();
    var query = (
      this.ref.collection('outfitFollowers')
    );
    if (this.lastOutfitFollower) {
      query = query.startAfter(this.lastOutfitFollower);
    }
    query = query.limit(OUTFIT_FOLLOWERS_PER_PAGE);

    var snapshot = await (
      query
      .get()
    );
    
    var {docs} = snapshot; 
    var newLast = docs[docs.length-1];
    if (newLast && docs.length == OUTFIT_FOLLOWERS_PER_PAGE ) { 
      this.lastOutfitFollower = newLast;
    } else {
      this.publicProductsLoadMoreEnabled = false;
      this.lastOutfitFollower = null;
    }

    //var promises = [];
    //docs.forEach(doc => promises.push(Product.fetchRef(doc.data().productRef)));
    //var products = await Promise.all(promises);
    this.outfitFollowers.replace(this.outfitFollowers.concat(docs));
    this.outfitFollowersLoading = false;
  }
  
}

export default Outfit;