import { useLinking } from '@react-navigation/native';
import * as Linking from 'expo-linking'

export default function(containerRef) {
  return useLinking(containerRef, {
    prefixes: [Linking.makeUrl('/'), 'https://styld.app/'],
    config: {
      STYLD: {
        path: 'root',
        screens: {
          tabs: {
            path: '/tabs',
            // exact: true,
            screens: {
              Home: {
                path: '/home/:gender?',
                //exact: true,
                screens: {
                  Home: {
                    path: '/home',
                    exact: true
                  },
                  product: {
                    path: '/product',
                    //exact: true,
                    screens: {
                      product: {
                        path: '/product',
                        exact: true
                      }
                    }
                  }
                }
              },
              Products: {
                path: '/products',
                exact: true,
                screens: {
                  product: {
                    path: 'product',
                    //exact: true,
                    screens: {
                      product: {
                        path: 'product',
                        exact: true,
                      }
                    }
                  },
                  Category: {
                    path: 'category/:categoryId',
                    exact: true
                  }
                }
              },
              Social: {
                path: '/social',
                exact: true,
                screens: {
                  Social: {
                    path: '/social',
                    exact: true,
                  }
                }
              },
              Brands: {
                path: '/brands',
                exact: true,
                screens: {
                  Brands: {
                    path: '/brands',
                    exact: true,
                  }
                }
              },
              Wishlists: {
                path: '/wishlists',
                exact: true,
                screens: {
                  Wishlists: {
                    path: '/wishlists',
                    exact: true,
                  },
                  product: {
                    path: '/product',
                    //exact: true,
                    screens: {
                      product: {
                        path: '/product',
                        exact: true
                      }
                    }
                  }
                }
              },
              Me: {
                path: '/me',
                exact: true,
                screens: {
                  Me: {
                    path: '/me',
                    exact: true,
                  },
                  product: {
                    path: '/product',
                    //exact: true,
                    screens: {
                      product: {
                        path: '/product',
                        exact: true
                      }
                    }
                  }
                }
              }
            }
          }
        }
        
      },

      
    },
  });
}
