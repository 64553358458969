import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity as TouchableElement,
} from 'react-native';
import px from '../utils/px';
import Device from '../utils/Device';
import COLORS from '../constants/Colors';
import FONTS from '../constants/Fonts';
import SIZES from '../constants/Sizes';
import Icon from '@expo/vector-icons/AntDesign';
import ActivityIndicator from './ActivityIndicator';
import Sizes from '../constants/Sizes';
import Fonts from '../constants/Fonts';
import FadeInView from './FadeInView';
import Analytics from '../services/Analytics';

export default class Button extends Component {
  onPress = () => {
    const {
      onPressDisabled,
      onPress,
      disabled,
      text,
      icon,
    } = this.props;
    Analytics.logEvent('button', {
      text,
      icon,
      disabled,
    });
    if (disabled && onPressDisabled) {
      onPressDisabled();
    } else if (!disabled && onPress) {
      onPress();
    }
  }
  render() {
    const {
      style,
      text,
      icon,
      disabled,
      paddingHorizontal,
      noVerticalPadding,
      outline,
      flex,
      fullWidth,
      transparent,
      danger,
      onPressDisabled,
      noHeight,
      iconComponent,
      square,
      yellow,
      whiteText,
      opacity,
      textColor,
      textLineThrough,
      loading,
      hideIconWhenLoading,
      noBorder,
      halfMarginLeft,
      halfMarginRight,
      withCheckbox,
      checkboxIsSelected,
      fontSize,
      bold,
      rowReverse,
      textWithNoMarginFromIcon,
      underline,
      textLeft,
      textRight,
      withRadio,
      radioIsSelected,
      multiline,
      twoThirds,
      inline,
      column,
      accessibilityRole,
      href,
    } = this.props;

    var IconComponent = Icon;
    if (iconComponent) {
      IconComponent = iconComponent;
    }
    return (
      
        <TouchableElement
        
        onPress={this.onPress}
        underlayColor='transparent'
        activeOpacity={1}
        style={[
          styles.container,
          disabled && styles.disabled,
          fullWidth && styles.fullWidth,
          yellow && styles.yellow,
          transparent && styles.transparent,
          outline && styles.outline,
          flex && styles.flex,
          noHeight && styles.noHeight,
          square && styles.square,
          danger && !transparent && styles.dangerContainer,
          !isNaN(opacity) && {opacity},
          noBorder && styles.noBorder,
          halfMarginRight && styles.halfMarginRight,
          halfMarginLeft && styles.halfMarginLeft,
          twoThirds && styles.twoThirds,
          inline && styles.inline,
          //styles.touchableWrapper,
          paddingHorizontal && styles.paddingHorizontal,
          noVerticalPadding && styles.noVerticalPadding,
          rowReverse && styles.rowReverse,
          textLeft && styles.contentLeft,
          column && styles.column,
          style,
        ]}
        disabled={ !onPressDisabled && disabled }
        {... accessibilityRole && {accessibilityRole}}
        {... href && {href}}
      >
        
          { icon && (!hideIconWhenLoading || !loading) && 
            <IconComponent 
              style={[
                styles.icon,
                transparent && styles.transparentButtonIcon,
                danger && transparent && styles.transparentButtonDangerText,
                this.props.iconStyle,
                whiteText && styles.whiteText,
              ]} 
              name={icon} 
            />
          }
          {
            loading && <ActivityIndicator color={ transparent ? COLORS.BUTTON.BG : '#ffffff'} />
          }
          {(withCheckbox || withRadio) && 
            <View 
              style={[styles.checkbox, withRadio && styles.radio]} 
            >
              {(checkboxIsSelected || radioIsSelected) && 
                //<Icon name="check" style={{fontSize: px(10)}} />
                <FadeInView style={[styles.checkboxSelection, withRadio && styles.radioSelection ]} />
              }
            </View>
          }
          { text &&
            <Text  
              style={[
                styles.text,
                transparent && styles.transparentButtonText,
                danger && transparent && styles.transparentButtonDangerText,
                whiteText && styles.whiteText,
                text && !textWithNoMarginFromIcon && styles.textWithMarginFromIcon,
                textColor && {color: textColor},
                fontSize && {fontSize, lineHeight: fontSize + (Device.isDesktop ? 2: 2)  },
                textLineThrough && styles.textLineThrough,
                bold && styles.bold,
                underline && styles.underline,
                textLeft && styles.textLeft,
                textRight && styles.textRight,
                multiline && styles.multiline,
              ]}
              numberOfLines={!multiline ? 1 : 2}
            >
              {text}
            </Text>
            }
          
         
          </TouchableElement>
      
    )
  }
}

const styles = StyleSheet.create({
 
  container: {
    backgroundColor: COLORS.BUTTON.BG,
    borderColor: COLORS.BUTTON.BG,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: px(SIZES.BORDER_RADIUS),
    alignItems: 'center',
    height: (SIZES.LAYOUT_ITEM), // - (StyleSheet.hairlineWidth * 2),
    flexDirection: 'row',
    ...Device.select({
      web: {
        boxSizing: 'border-box',
        transitionDuration: "300ms",
        transitionProperty: "background-color",
        transitionTimingFunction: "linear"
      }
    }),
    justifyContent: 'center'
  },
  paddingHorizontal: {
    paddingHorizontal: Sizes.MARGIN,
  },
  column: {
    flexDirection: 'column',
  },
  square: {
    
    ...Device.select({
      desktop: {
        width: Sizes.LAYOUT_ITEM,
      },
      mobile: {
        width: Sizes.LAYOUT_ITEM,
        //aspectRatio: 1,
      }
    })
  },
  twoThirds: {
    height: (SIZES.LAYOUT_ITEM * 2 /3) - StyleSheet.hairlineWidth * 2,
  },
  inline: {
    height: Sizes.LINE_HEIGHT,
  },
  noBorder: {
    borderWidth: px(0),
  },
  // innerContainer: {
  //   paddingVertical: px(0),
  //   paddingHorizontal: Sizes.MARGIN / 2,
  //   flex: 1,
  //   //width: '100%',
  //   height: '100%',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'row',
  // },
  contentLeft: {
    //alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  dangerContainer: {
    backgroundColor: COLORS.danger,
    borderColor: COLORS.danger,
  },
  text: {
    fontFamily: FONTS.MAIN_LIGHT,
    color: COLORS.BUTTON.TEXT,
    backgroundColor: 'transparent',
    fontSize: (FONTS.BUTTON.SIZE),
    lineHeight: (FONTS.BUTTON.SIZE + 2),
    letterSpacing: 0.4,
    textAlign: 'center',
    ...Device.select({
      web: {
        
      }
    }),
  },
  textWithMarginFromIcon: {
    //marginLeft: px(4),
   // flex: 1,
  //  textAlign: 'center',
  },
  icon: {
    color: '#ffffff',
    fontSize: px(18),
    textAlign: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  transparentButtonDangerText: {
    color: COLORS.danger,
  },
  transparentButtonIcon: {
    color: COLORS.BUTTON.BG,
    
  },
  transparentButtonText: {
    color: "#000000",
    
  },
  disabled: {
    opacity: 1,
  },
  noHorizontalPadding: {
    paddingHorizontal: 0
  },
  noVerticalPadding: {
    paddingVertical: 0
  },
  outline: {
    borderColor: COLORS.BUTTON.BG,
  },
  flex: {
    flex: 1,
    // no more props here, just flex
    //margin: Sizes.MARGIN 
  },
  halfMarginLeft: {
    marginLeft: Sizes.MARGIN / 2,
  },
  halfMarginRight: {
    marginRight: Sizes.MARGIN / 2,
  },
  noHeight: {
    height: null,
  },
  yellow: {
    backgroundColor: '#C7A23D',
  },
  whiteText: {
    color: '#ffffff',
  },
  textLineThrough: {
    textDecorationLine: 'line-through', 
    textDecorationStyle: 'solid'
  },
  checkbox: {
    width: Device.isWeb ? px(16) : px(10), 
    height: Device.isWeb ? px(16) : px(10),
    backgroundColor: '#ffffff',
    borderColor: COLORS.BUTTON.BG,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 1,
    marginRight: SIZES.MARGIN,
    alignItems: 'center',
    justifyContent: 'center',
    padding: px(1),
  },
  radio: {
    borderRadius: px(6),
  },
  checkboxSelection: {
    width: px(8), 
    height:px(8),
    backgroundColor: COLORS.BUTTON.BG,
    borderRadius: 0,
  },
  radioSelection:{
    borderRadius: px(4),
  },
  bold: {
    fontFamily: Fonts.MAIN,
  },
  rowReverse: {
    flexDirection: 'row-reverse',
  },
  underline: {
    textDecorationLine: 'underline',
    textDecorationColor: "#666",
    
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  }
})
