import * as Font from 'expo-font'

async function init() {
  return Font.loadAsync({
    'Lato': require('../assets/fonts/Lato-Regular.ttf'),
    'Lato Light': require('../assets/fonts/Lato-Light.ttf'),
    'Lato Bold': require('../assets/fonts/Lato-Bold.ttf'),
    'Playfair Bold': require('../assets/fonts/PlayfairDisplay-Bold.ttf'),
  });
}

export default {init};