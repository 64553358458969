import * as React from 'react';
import loadable from "@loadable/component";
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Fonts from '../constants/Fonts';
import { 
  StyleSheet, 
  View, 
} from 'react-native';
import px from '../utils/px';
import MenuButton from '../components/MenuButton'
import Auth from '../services/Auth';
import Device from '../utils/Device';
import TabIcon from '@expo/vector-icons/FontAwesome5';

const AuthScreen = loadable(() => import('../screens/AuthScreen'));
const ProductScreen = loadable(() => import('../screens/ProductScreen'));
const FilterCategory = loadable(() => import('../components/FilterCategory'));
const FilterBrand = loadable(() => import('../components/FilterBrand'));
const MyProfileScreen = loadable(() => import('../screens/MeScreen'));
const ProductsScreen = loadable(() => import('../screens/ProductsScreen'));
const WishlistsScreen = loadable(() => import('../screens/WishlistsScreen'));
const OutfitsScreen = loadable(() => import('../screens/OutfitsScreen'));
const HomeScreen = loadable(() => import('../screens/HomeScreen'));
const CategoryScreen = loadable(() => import('../screens/CategoryScreen'));
const AddToWishlistScreen = loadable(() => import('../screens/AddToWishlistScreen'));
const AddToOutfitScreen = loadable(() => import('../screens/AddToOutfitScreen'));
const NewWishlistScreen = loadable(() => import('../screens/NewWishlistScreen'));
const NewOutfitScreen = loadable(() => import('../screens/NewOutfitScreen'));
const WishlistScreen = loadable(() => import('../screens/WishlistScreen'));
const OutfitScreen = loadable(() => import('../screens/OutfitScreen'));
const PriceRangeScreen = loadable(() => import('../components/categoryFiltering/PriceRangeScreen'));
const BrandPriceRangeScreen = loadable(() => import('../components/brandFiltering/PriceRangeScreen'));
const KeywordsScreen = loadable(() => import('../components/categoryFiltering/KeywordsScreen'));
const BrandKeywordsScreen = loadable(() => import('../components/brandFiltering/KeywordsScreen'));
const PrivacyPolicy = loadable(() => import('../screens/PrivacyPolicy'));
const BrandSizes = loadable(() => import('../components/brandFiltering/SizesScreen'));
const ProductWishlistsScreen = loadable(() => import('../screens/ProductWishlists'));
const ProductOutfitsScreen = loadable(() => import('../screens/ProductOutfits'));
const SocialFeedScreen = loadable(() => import('../screens/SocialFeedScreen'));
const ProfileScreen = loadable(() => import('../screens/Profile'));
const WishlistFollowersScreen = loadable(() => import('../screens/WishlistFollowers'));
const OutfitFollowersScreen = loadable(() => import('../screens/OutfitFollowers'));
const BrandsScreen = loadable(() => import('../screens/Brands'));
const BrandScreen = loadable(() => import('../screens/BrandScreen'));
const Search = loadable(() => import('../screens/Search'));
const AuthLoading = loadable(() => import('../screens/AuthLoading'));
const Onboarding = loadable(() => import('../screens/Onboarding'));
const CategoryBrands = loadable(() => import('../components/categoryFiltering/CategoryBrandsScreen'));
const CategorySizes = loadable(() => import('../components/categoryFiltering/CategorySizesScreen'));
const CategoryColors = loadable(() => import('../components/categoryFiltering/CategoryColorsScreen'));
const BrandColors = loadable(() => import('../components/brandFiltering/ColorsScreen'));

import ActivityIndicator from '../components/ActivityIndicator';
import MobileTabBar from './TabBar.mobile';
import DesktopTabBar from './TabBar.desktop';
import UnreadNumber from '../components/UnreadNumber';
import Header from './Header.mobile';

const TabBar = Device.isDesktop ? DesktopTabBar : MobileTabBar ;

const HEADER_COLOR = Device.isMobile ? '#ffffff' : '#222222';
const HEADER_BGCOLOR = Device.isMobile ? '#000000' : '#ffffff';

const HEADER_STYLE = {
  headerTintColor: HEADER_COLOR,
  headerTitleAlign: 'center', // android
  
  headerStyle: {
    elevation: 0, //for android
    shadowOpacity: 0, //for ios
    borderBottomWidth: StyleSheet.hairlineWidth, //for ios
    backgroundColor: HEADER_BGCOLOR,
  },
  headerTitleStyle: {
    fontFamily: Fonts.MAIN,
    fontSize: Fonts.SIZE,
    color:  HEADER_COLOR,
  },
}

const MAIN_HEADER_STYLE = {
  ...HEADER_STYLE,
  //title: 'STYLD',
  headerTitleStyle: {
    ...HEADER_STYLE.headerTitleStyle,
    fontFamily: Fonts.LOGO_BOLD,
    fontSize: Device.isWeb ? px(36) : px(20),
    letterSpacing: px(1),
    // flex: 1, // android, plan b
    // textAlign: 'center', // android, plan b
  },
}

if (Device.isDesktop) {
  MAIN_HEADER_STYLE.title = null;
  MAIN_HEADER_STYLE.headerStyle.height = 0;
}

const RootStack = createStackNavigator();

const AuthStack = createStackNavigator();

@observer
class AuthStackComponent extends React.Component {
  render() {
    return (
      <AuthStack.Navigator
        //initialRouteName="Onboarding"
        screenOptions={{
          //headerShown: false,
        }}
      >
        {/*Auth.isLoginNow && Device.isMobileApp && 
        <AuthStack.Screen
          name="AuthLoading"
          component={AuthLoading}
        />  
        */}
        {((!Auth.isLoggedIn)) && 
        
        <AuthStack.Screen
          name="AuthHome"
          component={AuthScreen}
        />
        }

        <AuthStack.Screen
          name="Onboarding"
          component={Onboarding}
        /> 
      
      </AuthStack.Navigator>
    );
  }
}


const AddToWishlistStack = createStackNavigator();

@observer
class AddToWishlistStackComponent extends React.Component {
  render() {
    return (
      <AddToWishlistStack.Navigator
        screenOptions={{
          title: null ,
          headerShown: true, 
          ...MAIN_HEADER_STYLE,
          headerBackTitleVisible: false,          
        }}
      >
        <AddToWishlistStack.Screen 
          name="AddToWishlist" 
          component={AddToWishlistScreen} 
        />
        <AddToWishlistStack.Screen 
          name="NewWishlist" 
          component={NewWishlistScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
      </AddToWishlistStack.Navigator>
    )
  }
}

const AddToOutfitStack = createStackNavigator();

@observer
class AddToOutfitStackComponent extends React.Component {
  render() {
    return (
      <AddToOutfitStack.Navigator
        screenOptions={{
          title: null ,
          headerShown: true, 
          ...MAIN_HEADER_STYLE,
          headerBackTitleVisible: false,          
        }}
      >
        <AddToOutfitStack.Screen 
          name="AddToOutfit" 
          component={AddToOutfitScreen} 
          options={{
            title: null ,
            headerShown: true, 
            ...MAIN_HEADER_STYLE,
            headerBackTitleVisible: false,          
          }}
        />
        <AddToOutfitStack.Screen 
          name="NewOutfit" 
          component={NewOutfitScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
      </AddToOutfitStack.Navigator>
    )
  }
}

const ProductsStack = createStackNavigator();

@observer
class ProductsStackComponent extends React.Component {
  render() {
    return (
      <ProductsStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {!Device.isDesktop &&
        <ProductsStack.Screen
          name="Products"
          component={ProductsScreen}
        />
        }
        
        <ProductsStack.Screen 
          name={"Category"} 
          component={CategoryScreen}           
        />

        
        <ProductsStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <ProductsStack.Screen 
          name="wishlist" 
          component={WishlistStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <ProductsStack.Screen 
          name="outfit" 
          component={OutfitStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        
        <ProductsStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <ProductsStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }
      
      </ProductsStack.Navigator>
    );
  }
}

const GlobalStack = createStackNavigator();

const AddToWishlistGlobal = (
  <GlobalStack.Screen 
    name="WishlistManagement" 
    component={AddToWishlistStackComponent} 
    options={{headerShown: false}}
  />
)

const AddToOutfitGlobal = (
  <GlobalStack.Screen 
    name="OutfitManagement" 
    component={AddToOutfitStackComponent} 
    options={{headerShown: false}}
  />
)


const HomeStack = createStackNavigator();

@observer
class HomeStackComponent extends React.Component {
  render() {
    return (
      <HomeStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        
        <HomeStack.Screen 
          name={"Home"} 
          component={HomeScreen}           
        />
        
        <HomeStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
           // headerBackTitleVisible: false,          
          })}
        />
         
          <HomeStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <HomeStack.Screen 
          name="wishlist" 
          component={WishlistStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <HomeStack.Screen 
          name="outfit" 
          component={OutfitStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <HomeStack.Screen 
          name={"Category"} 
          component={CategoryScreen}           
        />
        <HomeStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />
        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }
      </HomeStack.Navigator>
    );
  }
}

const WishlistsStack = createStackNavigator();

@observer
class WishlistsStackComponent extends React.Component {
  render() {
    return (
      <WishlistsStack.Navigator
        initialRouteName="Wishlists"
        screenOptions={{
          headerShown: false,
        }}
      >
        <WishlistsStack.Screen
          name="Wishlists"
          component={WishlistsScreen}
        />  
        
        <WishlistsStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
          
        <WishlistsStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        
          <WishlistsStack.Screen 
          name={"wishlist"} 
          component={WishlistStackComponent}           
        />

        <WishlistsStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }
      
      </WishlistsStack.Navigator>
    );
  }
}

const OutfitsStack = createStackNavigator();

@observer
class OutfitsStackComponent extends React.Component {
  render() {
    return (
      <OutfitsStack.Navigator
        initialRouteName="Outfits"
        screenOptions={{
          headerShown: false,
        }}
      >
        <OutfitsStack.Screen
          name="Outfits"
          component={OutfitsScreen}
        />  
        
        <OutfitsStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
          
        <OutfitsStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        
          <OutfitsStack.Screen 
          name={"outfit"} 
          component={OutfitStackComponent}           
        />

        <OutfitsStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }
      
      </OutfitsStack.Navigator>
    );
  }
}


const SocialFeedStack = createStackNavigator();

@observer
class SocialFeedStackComponent extends React.Component {
  render() {
    return (
      <SocialFeedStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <SocialFeedStack.Screen 
          name="Social" 
          component={SocialFeedScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <SocialFeedStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <SocialFeedStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        
        <SocialFeedStack.Screen 
          name={"wishlist"} 
          component={WishlistStackComponent}           
        />
        <SocialFeedStack.Screen 
          name={"outfit"} 
          component={OutfitStackComponent}           
        />

        <SocialFeedStack.Screen 
          name={"Category"} 
          component={CategoryScreen}           
        />
        <SocialFeedStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }
                
      </SocialFeedStack.Navigator>
    )
  }
}

const MyProfileStack = createStackNavigator();

@observer
class MyProfileStackComponent extends React.Component {
  render() {
    return (
      <MyProfileStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <MyProfileStack.Screen 
          name="Me" 
          component={MyProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <MyProfileStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <MyProfileStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
           // headerBackTitleVisible: false,          
          })}
        />
        
        <MyProfileStack.Screen 
          name={"wishlist"} 
          component={WishlistStackComponent}           
        />
        <MyProfileStack.Screen 
          name={"outfit"} 
          component={OutfitStackComponent}           
        />

        <MyProfileStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }

      </MyProfileStack.Navigator>
    )
  }
}

const SearchStack = createStackNavigator();

class SearchStackComponent extends React.Component {
  render() {
    return (
      <SearchStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <SearchStack.Screen 
          name="Search" 
          component={Search} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <SearchStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <SearchStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
           // headerBackTitleVisible: false,          
          })}
        />
        
        <SearchStack.Screen 
          name={"wishlist"} 
          component={WishlistStackComponent}           
        />
        <SearchStack.Screen 
          name={"outfit"} 
          component={OutfitStackComponent}           
        />

        <SearchStack.Screen 
          name={"Category"} 
          component={CategoryScreen}           
        />
        <SearchStack.Screen 
          name="brand" 
          component={BrandScreen} 
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }

      </SearchStack.Navigator>
    )
  }
}

const BrandsStack = createStackNavigator();

class BrandsStackComponent extends React.Component {
  render() {
    return (
      <BrandsStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <BrandsStack.Screen 
          name="Brands" 
          component={BrandsScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <BrandsStack.Screen 
          name="brand" 
          component={BrandScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <BrandsStack.Screen 
          name="user" 
          component={ProfileScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />

        <BrandsStack.Screen 
          name="product" 
          component={ProductStackComponent} 
          options={({ route }) => ({ 
           // headerBackTitleVisible: false,          
          })}
        />
        
        <BrandsStack.Screen 
          name={"wishlist"} 
          component={WishlistStackComponent}           
        />
        <BrandsStack.Screen 
          name={"outfit"} 
          component={OutfitStackComponent}           
        />

        <BrandsStack.Screen 
          name={"Category"} 
          component={CategoryScreen}           
        />

        { Device.isDesktop && AddToWishlistGlobal }
        { Device.isDesktop && AddToOutfitGlobal }

      </BrandsStack.Navigator>
    )
  }
}

const ProductStack = createStackNavigator();

@observer
class ProductStackComponent extends React.Component {
  render() {
    return (
      <ProductStack.Navigator
      screenOptions={{
        //headerShown: false,
      }}
      >
        <ProductStack.Screen 
          name="product" 
          component={ProductScreen} 
        />
        <ProductStack.Screen 
          name="product-wishlists" 
          component={ProductWishlistsScreen} 
        />
        <ProductStack.Screen 
          name="product-outfits" 
          component={ProductOutfitsScreen} 
        />
        
      </ProductStack.Navigator>
    )
  }
}

const WishlistStack = createStackNavigator();

@observer
class WishlistStackComponent extends React.Component {
  render() {
    return (
      <WishlistStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <WishlistStack.Screen 
          name="wishlist" 
          component={WishlistScreen} 
          // options={({ route }) => ({ 
          //   headerBackTitleVisible: false,          
          // })}
        />
        <WishlistStack.Screen 
          name="wishlist-followers" 
          component={WishlistFollowersScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <WishlistStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />
        <WishlistStack.Screen 
          name="outfit" 
          component={OutfitScreen} 
          // options={({ route }) => ({ 
          //   headerBackTitleVisible: false,          
          // })}
        />
      </WishlistStack.Navigator>
    )
  }
}

const OutfitStack = createStackNavigator();

@observer
class OutfitStackComponent extends React.Component {
  render() {
    return (
      <OutfitStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <OutfitStack.Screen 
          name="outfit" 
          component={OutfitScreen} 
          // options={({ route }) => ({ 
          //   headerBackTitleVisible: false,          
          // })}
        />
        <OutfitStack.Screen 
          name="outfit-followers" 
          component={OutfitFollowersScreen} 
          options={({ route }) => ({ 
            headerBackTitleVisible: false,          
          })}
        />
        <OutfitStack.Screen 
          name={"brand"} 
          component={BrandScreen}           
        />
        <OutfitStack.Screen 
          name="wishlist" 
          component={WishlistScreen} 
          // options={({ route }) => ({ 
          //   headerBackTitleVisible: false,          
          // })}
        />
      </OutfitStack.Navigator>
    )
  }
}

const FilterCategoryStack = createStackNavigator();

@observer
class FilterCategoryStackComponent extends React.Component {
  render() {
    return (
      <FilterCategoryStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <FilterCategoryStack.Screen 
          name="FitlerCategory" 
          component={FilterCategory} 
        />
        <FilterCategoryStack.Screen 
          name="PriceRange" 
          component={PriceRangeScreen} 
        />
        <FilterCategoryStack.Screen 
          name="Keywords" 
          component={KeywordsScreen} 
        />
        <FilterCategoryStack.Screen 
          name="Brands" 
          component={CategoryBrands}
        />
        <FilterCategoryStack.Screen 
          name="Sizes" 
          component={CategorySizes}
        />
        <FilterCategoryStack.Screen 
          name="Colors" 
          component={CategoryColors} 
        />
        
      </FilterCategoryStack.Navigator>
    )
  }
}

const FilterBrandStack = createStackNavigator();

@observer
class FilterBrandStackComponent extends React.Component {
  render() {
    return (
      <FilterBrandStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      >
        <FilterBrandStack.Screen 
          name="FilterBrand" 
          component={FilterBrand} 
        />
        <FilterBrandStack.Screen 
          name="PriceRange" 
          component={BrandPriceRangeScreen} 
        />
        <FilterBrandStack.Screen 
          name="Keywords" 
          component={BrandKeywordsScreen} 
        />
        <FilterBrandStack.Screen 
          name="Sizes" 
          component={BrandSizes} 
        />
        <FilterBrandStack.Screen 
          name="Colors" 
          component={BrandColors} 
        />
      </FilterBrandStack.Navigator>
    )
  }
}

const Tab = (
  Device.isDesktop ? 
  createMaterialTopTabNavigator :
  Device.isMobileWeb ? 
  createMaterialTopTabNavigator : 
  createBottomTabNavigator
)();

function Tabs() {
  var platformProps = {};
  platformProps.lazy = true;
  platformProps.tabBar = props => <TabBar {...props} />;
  return (
      <Tab.Navigator
      {...platformProps}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'Home') {
            iconName = focused ? 'home' : 'home';
          } else if (route.name === 'Me') {
            iconName = focused ? 'user-alt' : 'user-alt';
          } else if (route.name === 'Products') {
            iconName = focused ? 'shopping-bag' : 'shopping-bag';
          } else if (route.name === 'Wishlists') {
            iconName = focused ? 'gift' : 'gift';
          } else if (route.name === 'Outfits') {
            iconName = focused ? 'gift-o' : 'gift-o';
          } else if (route.name === 'Social') {
            iconName = focused ? 'bell' : 'bell';
          } else if (route.name === 'Search') {
            iconName = focused ? 'search' : 'search';
          } else if (route.name === 'Brands') {
            iconName = focused ? 'tags' : 'tags';
          }

          var el = (
            <TabIcon name={iconName} size={size} color={color} />
          );
          if (route.name == 'Social') {
            el = (
              <View>
                <UnreadNumber />
                {el}
              </View>
            )
          }
          return el;
        },
      })}
      >
        <Tab.Screen 
          name={"Home"} 
          component={HomeStackComponent} 
        />
        
        <Tab.Screen 
          name="Products" 
          component={ProductsStackComponent}
          //unmountOnBlur={true}
        />
        <Tab.Screen 
          name="Brands" 
          //options={{title: 'Brands'}}
          component={BrandsStackComponent}
          //unmountOnBlur={true}
        />
        <Tab.Screen 
          name="Social" 
          component={SocialFeedStackComponent}
          //unmountOnBlur={true}
        />
        <Tab.Screen 
          name="Wishlists" 
          component={WishlistsStackComponent}
        />
        <Tab.Screen 
          name="Outfits" 
          component={OutfitsStackComponent}
        />
        <Tab.Screen 
          name={"Search"} 
          component={SearchStackComponent} 
        />
        {Auth.isLoggedIn &&
        <Tab.Screen 
          name="Me" 
          options={{
            tabBarLabel:  "My Profile",
          }}
          component={MyProfileStackComponent}
        />
        }
        {!Auth.isLoggedIn &&
        <Tab.Screen 
          name="Me" 
          options={{
            tabBarLabel:  "Sign in",
          }}
          component={AuthStackComponent}
        />
        }
      </Tab.Navigator>
  );
}

@observer
class RootStackScreen extends React.Component {
  render() {

  if (Auth.authStateInitialLoading) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator />
      </View>
    );
  }

  return (
    <RootStack.Navigator 
      mode={Device.isMobileApp ? "modal" : "card"}
      screenOptions={Device.isDesktop ? 
        MAIN_HEADER_STYLE :
        { header: ({navigation}) => <Header navigation={navigation} /> }
      }
    >
      <RootStack.Screen
        name="tabs"
        component={Tabs}
        // options={() => ({ 
        //   headerLeft: ()=><MenuButton  />,
        //   //headerRight: ()=><SearchButton  />,
        // })}
      />
      {!Device.isDesktop &&
      <RootStack.Screen 
        name="CategoryFilterModal" 
        component={FilterCategoryStackComponent} 
        options={{
          //headerShown: Device.isDesktop,
          headerLeft: null,
        }}
      />
      }
      {!Device.isDesktop &&
        <RootStack.Screen 
          name="BrandFilterModal" 
          component={FilterBrandStackComponent} 
          options={{
            //headerShown: Device.isDesktop,
          headerLeft: null,
          }}
        />
        }
      <RootStack.Screen 
        name="Auth" 
        component={AuthStackComponent} 
        options={({ route }) => {
          
          var options = {
            //headerShown: false, 
            //headerLeft: null ,
            headerBackTitleVisible: false,  
          }
          if (!route.params || !route.params.backEnabled) {
            options.headerLeft = null
          }
          return options;
        }}
      />
      {Device.isMobile &&
        AddToWishlistGlobal
      }
      {Device.isMobile &&
        AddToOutfitGlobal
      }
      <RootStack.Screen 
        name="privacy-policy" 
        component={PrivacyPolicy} 
        options={({ route }) => ({
          headerBackTitleVisible: false,  
        })}
      />
     
    </RootStack.Navigator>
  );
  }
}

export default (RootStackScreen);
