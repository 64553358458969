import React, { useRef } from 'react';
import {  
  View,
  TouchableOpacity, 
  StyleSheet, 
  Text,
} from 'react-native';
import Animated from 'react-native-reanimated';
import Sizes from '../constants/Sizes';
import Device from '../utils/Device';
import px from '../utils/px';
import Fonts from '../constants/Fonts';
import ProductsScreen from '../screens/ProductsScreen';
import useOutsideClick from '../utils/useOutsideClick';
import UnreadNumber from '../components/UnreadNumber';
import Styles from '../constants/Styles';
import Button from '../components/Button';
import UserIcon from '@expo/vector-icons/FontAwesome';
import Colors from '../constants/Colors';


export default function TabBar({ state, descriptors, navigation, position }) {
  return (
      
    <View style={styles.container}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        if (route.name == 'Search') return null;
        if (route.name == 'Me') return null;

        const onPress = () => {
          navigation.navigate(route.name, {
            screen: route.name
          })
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        // const inputRange = state.routes.map((_, i) => i);
        // const opacity = Animated.interpolate(position, {
        //   inputRange,
        //   outputRange: inputRange.map(i => (i === index ? 1 : 0)),
        // });

        return (
          <TouchableOpacity
            key={label}
            activeOpacity={0.8}
            accessibilityRole="button"
            accessibilityStates={isFocused ? ['selected'] : []}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[
              styles.button,
              isFocused && styles.buttonActive,
            ]}
          >
            <Animated.Text 
              //style={{ opacity }} 
              style={[
                styles.buttonText,
                isFocused && styles.buttonTextActive,
              ]}
            >
              {label.toUpperCase()}
              
            </Animated.Text>
            {label == 'Social' && <UnreadNumber />}
          </TouchableOpacity>
        );
      })}
    </View>
    
  );
}


const styles = StyleSheet.create({
  container: { 
    // zIndex is a fix 
    // for weird transparent click 
    // from popup windows
    zIndex: 0,

    //width: Math.max(Device.width / 2 , px(1200)),
    flexDirection: 'row' ,
    height: Sizes.LAYOUT_ITEM, //Sizes.LINE_HEIGHT + Sizes.MARGIN,
    paddingHorizontal: Sizes.MARGIN,
   // flex: 1,
    //flexGrow: 0,
    //alignSelf: 'center',
    justifyContent: 'space-between',
    borderStyle: 'solid',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Colors.SEPARATOR,
    borderBottomWidth: 1,
    borderBottomColor: 'transparent',
  },
  
  button: {


    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonActive: {
    ...Device.select({
      mobile: {
        borderBottomColor: '#000000',
      },
      mobileWeb: {

      }
    })
    
  },
  buttonText: {
    ...Styles.textLight,
    ...Device.select({
      mobile: {
        fontSize: px(7),
      }, 
      mobileWeb: {
        fontSize: px(12),
      }
    })
    
    //letterSpacing: 1,
  },
  buttonTextActive: {
    ...Device.select({
      mobile: {
        
      },
      mobileWeb: {
        textDecorationLine: 'underline',
        textDecorationColor: Fonts.COLOR,
      }
    })
  },
  
  searchButton: {
    //marginRight: px(-Sizes.LAYOUT_ITEM)
  }
})