import React from 'react';
import { StyleSheet, View, Text, ScrollView, Image } from 'react-native';
import Button from '../components/Button';
import Sizes from '../constants/Sizes';
import Auth from '../services/Auth'
import Fonts from '../constants/Fonts';
import ActivityIndicator from '../components/ActivityIndicator';
import { observer } from 'mobx-react';
import Icon from '@expo/vector-icons/FontAwesome';
import FadeInView from '../components/FadeInView';
import TextInput from '../components/TextInput';
import { observable } from 'mobx';
import Colors from '../constants/Colors';
import px from '../utils/px';
import FormInputLabel from '../components/FormInputLabel';
import SelectBox from '../components/SelectBox';
import Device from '../utils/Device';
import COUNTRIES from '../constants/Countries';

@observer
export default class UserDetails extends React.Component {  
  render () {
    if (! Auth.user ) return <ActivityIndicator />;
    return (
      <View style={styles.fields}>
        <TextInput
          value={ Auth.user.name}
          placeholder={'Full Name'}
          onChangeText={val => this.update({name: val})}
          outline={1}
          label={'Full Name'}
          style={styles.field}
          
        />
        <TextInput
          value={ Auth.user.username}
          placeholder={'Username'}
          onChangeText={val => this.update({username: val})}
          outline={1}
          label={'Username'}
          style={styles.field}
          
        />
        {Auth.isAnonymous &&
          <TextInput
          value={ Auth.user.email}
          placeholder={'Email'}
          onChangeText={val => this.update({email: val})}
          outline={1}
          label={'Email'}
          style={styles.field}
          
        />
        }
        {/*
        <FormInputLabel label>Location</FormInputLabel>
        <SelectBox 
          style={styles.field} 
          placeholder={"Select country"}
          title={Auth.user.country && Auth.user.country.name }
          customPreElement={
            Auth.user.country && 
            <Image
              source={{uri: 
                "https://www.countryflags.io/" +
                Auth.user.country.code.toLowerCase() + 
                "/flat/48.png"
              }}
              style={{width: px(20), height: px(20)}}
            />
          }
          actions={(
            COUNTRIES.map(country => ({
              title: ''+country.name, 
              action: () => Auth.user.ref.update({country})
            })
          ))}
          //destructiveCancel
          // titleInActionSheet="Select size"
          concatClose={!Device.isAndroid}
          //titleInActionSheet={titleInActionSheet}
          // titleTextStyle={Device.isAndroid ? {
          //   alignSelf: 'flex-end',
          //   textAlign: 'right',
          // } : null}
        />
          */}
        <FormInputLabel label>Shop Preference</FormInputLabel>
        <View style={{flexDirection: 'row', flex: 1}}>
        <Button
          key={'Women'}
          text={'Women'}
          withCheckbox
          checkboxIsSelected={Auth.user.isFemale}
          onPress={()=>this.update({gender:'female'})}
          transparent //={isSelected}
          //outline
          style={styles.keyword}
          noHorizontalPadding
          noVerticalPadding
          noHeight
          textLeft
          style={Device.isMobile ? {width: '50%'} : {marginRight: Sizes.MARGIN}}
        />
        <Button
          key={'Men'}
          text={'Men'}
          withCheckbox
          checkboxIsSelected={Auth.user.isMale}
          onPress={()=>this.update({gender:'male'})}
          transparent //={isSelected}
          //outline
          style={styles.keyword}
          noHorizontalPadding
          noVerticalPadding
          textLeft
          style={Device.isMobile ? {width: '50%'} : {marginRight: Sizes.MARGIN}}
          noHeight
        />
        </View>
      </View>
    )
  }
  update = (obj) => {
    Auth.user.debouncedUpdate(obj)
  }
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
    padding: Sizes.MARGIN
  },
  button: {
    marginVertical: Sizes.MARGIN /2 
  },
  title: {
    fontFamily: Fonts.MAIN,
    fontSize: Sizes.TEXT,
    color: Colors.TEXT,
    textAlign: 'center',
    marginTop: Sizes.MARGIN* 2,
    marginVertical: Sizes.MARGIN,
  },
  description: {
    //height: Sizes.LAYOUT_ITEM,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Sizes.MARGIN,
    textAlign: 'center',
    fontFamily: Fonts.MAIN_LIGHT,
    fontSize: Sizes.TEXT,
    color: Colors.TEXT,
    //marginTop: Sizes.MARGIN,
  },
  fields: {
    marginTop: Sizes.MARGIN,
    flex: 1,
  },
  field: {
    marginBottom: Sizes.MARGIN,
  },
  keyword: {
    marginBottom: 0, //, Sizes.MARGIN/2,
    marginRight: Sizes.MARGIN,
  },
})