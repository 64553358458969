import { useEffect } from "react";

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideClick(ref, onPressOutside) {
  
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        document.removeEventListener("mousedown", handleClickOutside);
        if (ref.current && !ref.current.contains(event.target)) {
          onPressOutside();
          
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
  
}

export default useOutsideClick;