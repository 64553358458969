import React from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator as ActivityIndicatorNative,
} from 'react-native';
import COLORS from '../constants/Colors';
import px from '../utils/px';

export default class ActivityIndicator extends React.Component {
  render () {
    const {
      style,
      color,
      size,
    } = this.props;
    return (
      <ActivityIndicatorNative 
        style={[
          styles.container,
          style,
        ]}
        size={size}
        color={color || COLORS.INDICATOR} />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: px(32),
  }
});
