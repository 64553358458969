import { observable, computed, reaction } from 'mobx';
import Auth from '../services/Auth';
import * as firebase from 'firebase/app';
import "firebase/firestore";
import mergeAndSum from '../utils/mergeAndSum';
import sortBy from '../utils/sortBy';
import sortByPrice from '../utils/sortByPrice';
import Product from './Product';
import Device from '../utils/Device';

const PRODUCTS_PER_PAGE = 25;

class CategoryModel {
  id = null;
  name = null;
  gender = null;
  description = null;
  @observable parent = null;
  @observable products = [];
  @observable keywords = null;
  @observable loadMoreEnabled = true;
  @observable loading = false;
  @observable keywordsData = null; //// Comes from /partners/partner/categories/keyword
  last = null; // cursor in feed
  @observable filter = {
    keywords: [],
    lowPrice: null,
    highPrice: null,
    brands: [],
    sizes: [],
    colors: [],
  };
  @observable sort = {
    by: null,
    order: null,
  }
  static _all = {};
  static get all() {
    return this._all;
  }
  static find(id) {
    return this.all[id];
  }
  constructor({keywords, gender, name, description, parent}) {
    var id = gender + name;
    if (this.constructor.all[id]) throw new Error({message: 'Category already exists: ' + id })
    this.gender = gender;
    this.keywords = keywords;
    this.name = name;
    this.description = description;
    this.id = id;
    this.parent = parent;
    this.constructor.all[this.id] = this;
  }
  @computed get counter() {
    // 1. No keywords data yet loaded, so return void
    // 2. Price range filtering set , so no exact counter possible
    //    so showing product.length + '+' 
    // 3. No keywords filter - showing aggregation counter for all keywords
    // 4. Keywords filter set - aggregating only relevant keywords counters
    // 5. Brands filter set - aggregating only relevant brands counters
    // 6. Sizes filter set
    // 7. Colors filter set
    if (!this.keywordsData) return;
    let {
      filter,
    } = this;
    if (filter.lowPrice || filter.highPrice) {
      return this.products.length + ( this.loadMoreEnabled ? '+' : '');
    } 
    if (filter.sizes.length) {
      return this.products.length + ( this.loadMoreEnabled ? '+' : '');
    } 
    if (filter.colors.length) {
      return this.products.length + ( this.loadMoreEnabled ? '+' : '');
    } 
    var {
      brands,
      keywords,
    } = filter;
    var filteredKeywords = keywords.length ? keywords : this.keywords;
    return (
      Object.entries(this.keywordsData)
        .reduce((acc, [key, val]) => 
          acc + (filteredKeywords.includes(key) ? 
            brands.length ?
              Object.entries(val.brands)
                .reduce((totalBrandsCount, [brandKey, brandCount]) => 
                  totalBrandsCount + (brands.includes(brandKey) ? brandCount.count : 0) 
                , 0)
            :
              val.count
          : 0) , 0)
    )
  }
  @computed get brands() {
    if (!this.keywordsData) return [];
    let {
      filter,
    } = this; 
    var filteredKeywords = filter.keywords.length ? filter.keywords : this.keywords;
    
    var brands = Object.entries(
      Object.entries(this.keywordsData)
      .reduce((acc, [key, val]) =>  mergeAndSum(acc, filteredKeywords.includes(key) ? 
        val.brands
      : {}) , {})
    ).map(([title, counters])=>({title, ...counters })) ;
    return brands;
  }
  @computed get sizes() {
    if (!this.keywordsData) return;
    let {
      filter,
    } = this; 
    var filteredKeywords = filter.keywords.length ? filter.keywords : this.keywords;
    
    var sizes;
    if (!filter.brands.length) {
      sizes = Object.entries(
        Object.entries(this.keywordsData)
        .reduce((acc, [keywordKey, keywordData]) =>  mergeAndSum(acc, filteredKeywords.includes(keywordKey) ? 
          keywordData.sizes
        : {}) , {})
      ).map(([title, data ])=>({title, ...data})) ;
    } else {

      sizes = Object.entries(
        Object.entries(this.brands)
        .reduce((acc, [key, {title, sizes}]) =>  mergeAndSum(acc, filter.brands.includes(title) ? 
          sizes
        : {}) , {})
      ).map(([title, data ])=>({title, ...data})) ;
      
    }
    
    return sizes;
  }
  @computed get colors() {
    if (!this.keywordsData) return;
    let {
      filter,
    } = this; 
    var filteredKeywords = filter.keywords.length ? filter.keywords : this.keywords;
    
    var colors;
    if (!filter.brands.length) {
      if (filter.sizes.length) {
        colors = Object.entries(
          Object.entries(this.sizes)
          .reduce((acc, [key, {title, colors}]) => 
            mergeAndSum(acc, filter.sizes.includes(title) ? 
              colors
              : {}
            ) , {})
        ).map(([title, count ])=>({title, count})) ;
      } else {
        colors = Object.entries(
          Object.entries(this.keywordsData)
          .reduce((acc, [key, val]) =>  mergeAndSum(acc, filteredKeywords.includes(key) ? 
            val.colors 
          : {}) , {})
        ).map(([title, data ])=>({title, ...data})) ;
      }
    }  else {
      colors = Object.entries(
        Object.entries(this.brands)
        .reduce((acc, [key, {title, colors}]) =>  mergeAndSum(acc, filter.brands.includes(title) ? 
        colors
        : {}) , {})
      ).map(([title, data ])=>({title, ...data})) ;
      
    }
    return colors;
  }
  @computed get hasFilteredKeywords() {
    return this.filter.keywords.length &&  this.filter.keywords.length != this.keywords.length;
  }
  @computed get hasFilteredBrands() {
    return this.filter.brands.length &&  this.filter.brands.length != this.brands.length;
  }
  @computed get hasFilteredColors() {
    return this.filter.colors.length &&  this.filter.colors.length != this.colors.length;
  }
  @computed get hasFilteredSizes() {
    return this.filter.sizes.length &&  this.filter.sizes.length != this.sizes.length;
  }
  async fetchData() {
    this.listenToFiltering();
    await this.fetchProducts();
    this.fetchKeywordsData();
  }
  listenToFiltering() {
    const changeFilterAndFetchProducts = () => {
      this.onFilterChange();
      this.fetchProducts();
    }
    reaction(() => this.filter.keywords.length, () => {
      this.filter.brands.replace([]);
      changeFilterAndFetchProducts();
    });
    reaction(() => this.filter.lowPrice, changeFilterAndFetchProducts);
    reaction(() => this.filter.highPrice, changeFilterAndFetchProducts);
    reaction(() => this.sort.by, changeFilterAndFetchProducts);
    reaction(() => this.sort.order, changeFilterAndFetchProducts);
    reaction(() => this.filter.brands.length, () => {
      this.filter.colors.replace([]);
      this.filter.sizes.replace([]);

      // on mobile this one is heavy when reacting in real-time and ui is affected
      // so doing it only while leaving the related filter screen ("blur" event)
      Device.isDesktop && changeFilterAndFetchProducts()
    });
    // on mobile this one is heavy when reacting in real-time and ui is affected
    // so doing it only while leaving the related filter screen ("blur" event)
    if (Device.isDesktop) {
      reaction(() => this.filter.sizes.length, () => {
        changeFilterAndFetchProducts();
      });
      reaction(() => this.filter.colors.length, () => {
        changeFilterAndFetchProducts();
      });
    }
  }
  async fetchProductsFromPseudoFirestore() {
    var db = firebase.firestore();
    let {
      gender,
      filter,
      sort,
      name,
    } = this;
    var _brands = filter.brands;
    var _sizes = filter.sizes;
    var _colors = filter.colors;

    var promises;
    const where = (prop, condition, arr) => {
      var promises = arr.map(item => {
        var query = db.collectionGroup('products_' + gender);
        if (this.hasFilteredKeywords) {
          query = query.where('category', 'in', filter.keywords)
        } else {
          query = query.where('categoryParent', '==', name);
        }
          
        query = query.where(prop , condition, item)    
        
        if (this.filter.lowPrice) {
          query = query.where('price', '>=', Number(this.filter.lowPrice));
        }
        if (this.filter.highPrice) {
          query = query.where('price', '<=', Number(this.filter.highPrice));
        }
        
        return query.get();
      });
      return promises;
    }

    if (_brands.length) {
      promises = where('brand', '==', _brands);
    } else if (_sizes.length) {
      promises = where( 'sizes', 'array-contains', _sizes);
    } else if (_colors.length) {
      promises = where( 'colors', 'array-contains', _colors);
    }

    this.loading = true;

    var response = await Promise.all(promises);
    var docs = response.reduce((acc, val) => acc.concat(val.docs), [])
    var results = docs;//.map(doc=>{ Product.cache(doc); return doc.data()});

    if (_brands.length && _sizes.length && !_colors.length) {
      results = results.filter(r => r.data().sizes.filter(s=>_sizes.includes(s)).length)
    }
    if (_brands.length && !_sizes.length && _colors.length) {
      results = results.filter(r => r.data().colors.filter(s=>_colors.includes(s)).length)
    }
    if (!_brands.length && _sizes.length && _colors.length) {
      results = results.filter(r => r.data().colors.filter(s=>_colors.includes(s)).length)
    }
    if (_brands.length && _sizes.length && _colors.length) {
      results = results.filter(r => r.data().colors.filter(s=>_colors.includes(s)).length)
      results = results.filter(r => r.data().sizes.filter(s=>_sizes.includes(s)).length)
    }

    if (sort.by == 'title') {
      sortBy(results, sort.order, 'title');
    }
    if (sort.by == 'brand') {
      sortBy(results, sort.order, 'brand');
    }
    if (sort.by == 'price') {
      sortByPrice(results, sort.order);
    }
    if (sort.by == 'wishlistsCount') {
      sortBy(results, sort.order, 'wishlistsCount');
    }
    this.products.replace(results);
    this.loadMoreEnabled = false;
    this.loading = false;
  }
  
  // category: String
  // categoryParent: String
  // brand: String
  // sizes: Array
  // colors: Array

  // Restrictions:
  // You cannot use 'array-contains-any' filters with 'in' filters
  // You cannot use more than one 'in' filter.
  // You cannot use more than one 'array-contains-any' filter.

  // Remote:
  // +category -keywords -brands -sizes -colors
  // -category +keywords -brands -sizes -colors
  // +category -keywords +brands -sizes -colors
  // +category -keywords -brands +sizes -colors
  // +category -keywords -brands -sizes +colors


  fetchProducts = async () => {
    if (this.loading) return;
    
    if (
        ( this.hasFilteredKeywords && !this.hasFilteredBrands && !this.hasFilteredSizes && !this.hasFilteredColors)
      ||( !this.hasFilteredKeywords && !this.hasFilteredBrands && !this.hasFilteredSizes && !this.hasFilteredColors)
      ||( !this.hasFilteredKeywords && this.hasFilteredBrands && !this.hasFilteredSizes && !this.hasFilteredColors)
      ||( !this.hasFilteredKeywords && !this.hasFilteredBrands && this.hasFilteredSizes && !this.hasFilteredColors)
      ||( !this.hasFilteredKeywords && !this.hasFilteredBrands && !this.hasFilteredSizes && this.hasFilteredColors)
      ) {
      return this.fetchProductsFromRemote();
    } else {
      return this.fetchProductsFromPseudoFirestore()
    }

  }

  fetchProductsFromRemote = async () => {
    var db = firebase.firestore();
    let {
      gender,
      keywords,
      filter,
      name,
    } = this;
    

    // https://firebase.google.com/docs/firestore/query-data/get-data#source_options

    var query = (
      db.collectionGroup('products_' + gender)
    );

    if (this.hasFilteredKeywords) {
      query = query.where('category', 'in', filter.keywords)
    } else {
      query = query.where('categoryParent', '==', name);

      if ( this.hasFilteredBrands ) {
        query = query.where('brand', 'in', filter.brands)
      } else if ( this.hasFilteredSizes ) {
        query = query.where('sizes', 'array-contains-any', filter.sizes)
      } else if ( this.hasFilteredColors ) {
        query = query.where('colors', 'array-contains-any', filter.colors)
      }
    }

    if (this.filter.lowPrice) {
      query = query.where('price', '>=', Number(this.filter.lowPrice));
    }
    if (this.filter.highPrice) {
      query = query.where('price', '<=', Number(this.filter.highPrice));
    }

    if (this.sort.by) {
      var sortBy = this.sort.by;
      var sortOrder = this.sort.order;
      if ((this.filter.lowPrice || this.filter.highPrice) && sortBy != 'price') {
        sortBy = 'price';
        //sortOrder = null;
      }

      query = query.orderBy(sortBy, sortOrder);
    } else {
      var sortBy = 'updated_at';
      if ((this.filter.lowPrice || this.filter.highPrice)) {
        sortBy = 'price';
        //sortOrder = null;
      }
      query = query.orderBy(sortBy, 'desc');
    }

    if (this.last) {
      query = query.startAfter(this.last);
    }
    query = query.limit(PRODUCTS_PER_PAGE);
    
    this.loading = true;

    var productsSnapshot = await (
      query
      //.withConverter(productConverter)        
      .get()
    );
    
    productsSnapshot.forEach(product => new Product(product));
    
    var {docs} = productsSnapshot; 
    var newLast = docs[docs.length-1];
    if (newLast && docs.length == PRODUCTS_PER_PAGE ) { 
      this.last = newLast;
    } else {
      this.loadMoreEnabled = false;
      this.last = null;
    }
    this.products.replace(this.products.concat(docs));

    this.loading = false;  
  }

  fetchProductsNextPage() {
    this.fetchProducts();
  }

  // Comes from /partners/partner/categories/keyword
  fetchKeywordsData = async () => {
    var db = firebase.firestore();
    var {
      keywords,
      gender,
    } = this;
    var snap = await (
      db
        .collectionGroup('categories_' + gender)
        .where('id', 'in', keywords)
        .get()
    )
    var keywordsData = {};
    snap.forEach(keywordDoc => {
      var data = keywordDoc.data();
      var keyword = keywordsData[keywordDoc.id];
      if (!keyword) {
        keyword = { 
          count: 0, brands: {}, sizes: {}, colors: {},
        }
        keywordsData[keywordDoc.id] = keyword;
      }
      keywordsData[keywordDoc.id] = mergeAndSum(keyword, data);
    
    });
    this.keywordsData = keywordsData;
  }

  onFilterChange() {      
    //this.page = 1;
    this.last = null;
    this.products.replace([]);
    this.loadMoreEnabled = true; 
  }

  addKeywordFilter(keyword) {
    if (!keyword || !keyword.length) return;
    this.filter.keywords.push(keyword);
  }
  resetFilter() {
    this.filter = {
      keywords: [],
      lowPrice: null,
      highPrice: null,
      brands: [],
      sizes: [],
      colors: [],
    };
  }
  resetFilterAndSort() {
    this.resetFilter();
    this.sort = {
      by: null,
      order: null,
    }
  }

}

export default CategoryModel;