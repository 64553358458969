export default function sortByPrice(arr, order) {
  arr.sort((a,b) => {
    if (typeof a.data == 'function') a = a.data();
    if (typeof b.data == 'function') b = b.data();
    var aPrice = a.sale_price || a.price;
    var bPrice = b.sale_price || b.price;
    if (order == 'asc')
      return aPrice - bPrice ;
    else 
      return bPrice - aPrice ;
  })
}