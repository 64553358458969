import React from 'react';
import { 
  StyleSheet, 
  Text,
} from 'react-native';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';

const FormInputLabel = ({children, label}) => {
  return (
    <Text style={styles.label}>{children || label}</Text>
  )
}

const styles = StyleSheet.create({
  label: {
    fontFamily: Fonts.MAIN_LIGHT,
    color: Colors.TEXT,
    fontSize: (Fonts.SIZE),
    marginBottom: Sizes.MARGIN/2,
  },
});

export default FormInputLabel;