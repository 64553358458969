import React from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Button from '../components/Button';
import Sizes from '../constants/Sizes';
import Auth from '../services/Auth'
import Fonts from '../constants/Fonts';
import ActivityIndicator from '../components/ActivityIndicator';
import { observer } from 'mobx-react';
import Icon from '@expo/vector-icons/FontAwesome';
import FadeInView from '../components/FadeInView';
import TextInput from '../components/TextInput';
import { observable, reaction } from 'mobx';
import Colors from '../constants/Colors';
import UserDetails from './UserDetails';
import LoginInvite from '../components/LoginInvite';
import BackIcon from '@expo/vector-icons/AntDesign';
import { DrawerItem, DrawerContentScrollView } from '@react-navigation/drawer';

import Device from '../utils/Device';
import px from '../utils/px';
import CategoriesMenu from '../models/CategoriesMenu';
import CategoryModel from '../models/Category';
import Styles from '../constants/Styles';

const menuItemStyle = {
  borderBottomColor: 'transparent',
  borderBottomStyle: 'solid',
  borderBottomWidth: StyleSheet.hairlineWidth,
  marginBottom: 0,
  marginTop: 0,
  height: Sizes.LAYOUT_ITEM,
  ...Device.select({
    desktop: {
      paddingHorizontal: Sizes.MARGIN,
    },
  })
  
}

const noBottomBorder = {
  borderBottomColor: 'transparent',
}

const menuItemLabelStyle = {
  ...Styles.text,
}

const isLevelZero = () => !CategoriesMenu.backCategories.length;

@observer
export default class ProductsScreen extends React.Component {
  render () {
    if (CategoriesMenu.loading) return <ActivityIndicator />
    if (Device.isDesktop) {
      return <DesktopMenu categoryOnSelect={this.props.categoryOnSelect} {...this.props} />
    }

    const {
      navigation,
    } = this.props;
    const levelZero = isLevelZero();
    
    return (
      <DrawerContentScrollView 
        style={styles.container}
        keyboardShouldPersistTaps="handled"
      >
        {!levelZero && 
        <DrawerItem
          label={""}
          icon={()=><BackIcon style={menuItemLabelStyle} name={'back'} />}
          onPress={() => {
            CategoriesMenu.backFrom = CategoriesMenu.current;
            CategoriesMenu.current = CategoriesMenu.backCategories.pop() ;
          }}
          style={[menuItemStyle, noBottomBorder]}
          //labelStyle={menuItemLabelStyle}
        />}
        {/*menu.category.categories[0] && menu.category.categories[0].keywords &&
          <DrawerItemList {...props} />
        */}
        {//menu.category.categories[0] && !menu.category.categories[0].keywords &&
          CategoriesMenu.current.categories && CategoriesMenu.current.categories.map(category => 
            <Button
              //activeBackgroundColor='#ffffff'
              style={menuItemStyle}
              textLeft
              twoThirds
              //labelStyle={menuItemLabelStyle}
              //focused={(CategoriesMenu.backFrom && CategoriesMenu.backFrom.name === category.name)
              //  || CategoriesMenu.current == category.id}
              transparent={CategoriesMenu.current != category}
              text={category.name}
              key={category.name}
              onPress={() => {
                if (category.keywords) {
                  navigation.navigate( "Products");
                  navigation.push( "Category",
                    {categoryId: category.id}
                  )
                } else {
                  CategoriesMenu.backCategories.push(CategoriesMenu.current);
                  CategoriesMenu.current = category;
                }
                
              }}
            />
          )
        }
        
      </DrawerContentScrollView>
      
    )
  }
    
}

const ACTIVE_BG = '#eeeeee';

function initWebLocationListeners () {
      /* These are the modifications: */
      history.pushState = ( f => function pushState(){
        var ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.pushState);
  
      history.replaceState = ( f => function replaceState(){
        var ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.replaceState);
  
      window.addEventListener('popstate',()=>{
        window.dispatchEvent(new Event('locationchange'))
      });
}


@observer
class DesktopMenu extends React.Component {
  @observable level1 = null;
  @observable level2 = null;
  @observable level3 = null;

  componentDidMount() {
    initWebLocationListeners();
    this.listenForLocationChange();
    this.locationChanged();
  }
  listenForLocationChange() {
    window.addEventListener('locationchange', this.locationChanged);
  }
  locationChanged = () => {    
    var queryString = (() => window.location.search)(); 
    let params = new URLSearchParams(queryString);
    let categoryId = (params.get("categoryId")); 
    if (categoryId) {
      var category = CategoryModel.find(categoryId);
      if (category) {
        var {id, gender, parent} = category;
        this.level1 = CategoriesMenu.current.categories.find(c => c.name.toLowerCase() == gender);
        this.level2 = this.level1.categories.find(c=>c.name == parent.name);
        this.level3 = category;
      }
    }
  }
  render() {
    const {
      categoryOnSelect,
      navigation
    } = this.props;
    return (
      <View style={styles.container}>
        <View style={{flex: 0.34}}>
          {
            CategoriesMenu.current.categories && CategoriesMenu.current.categories.map(category => 
              <Button
                //activeBackgroundColor={ACTIVE_BG}
                style={menuItemStyle}
                transparent={this.level1 != category}
                //labelStyle={menuItemLabelStyle}
                //focused={(CategoriesMenu.backFrom && CategoriesMenu.backFrom.name === category.name)
                //  || CategoriesMenu.current == category.id}
                //focused={this.level1 == category}
                text={category.name}
                key={category.name}
                onPress={() => {
                  this.level1 = category;
                  this.level2 = null;
                  // if (category.keywords) {
                  //   navigation.navigate( "Products");
                  //   navigation.push( "Category",
                  //     {categoryId: category.id}
                  //   )
                  //   //CategoriesMenu.current = category.id;
                  //   //onPress && onPress();
                  // } else {
                  //   CategoriesMenu.backCategories.push(CategoriesMenu.category);
                  //   CategoriesMenu.category = category;
                  // }
                }}
                twoThirds={true}
              />
            )
          }
        </View>
        { this.level1 && this.level1.categories && 
        <FadeInView style={{flex: 0.33}}>
        {
          this.level1.categories.map(category => 
            <Button
              //activeBackgroundColor={ACTIVE_BG}
              style={menuItemStyle}
              transparent={this.level2 != category}
              //labelStyle={menuItemLabelStyle}
              //focused={(CategoriesMenu.backFrom && CategoriesMenu.backFrom.name === category.name)
              //  || CategoriesMenu.current == category.id}
              //focused={this.level2 == category}
              text={category.name}
              key={category.name}
              onPress={() => {
                this.level2 = category;
                // if (category.keywords) {
                //   navigation.navigate( "Products");
                //   navigation.push( "Category",
                //     {categoryId: category.id}
                //   )
                //   CategoriesMenu.current = category.id;
                //   onPress && onPress();
                // } else {
                //   CategoriesMenu.backCategories.push(CategoriesMenu.category);
                //   CategoriesMenu.category = category;
                // }
              }}
              twoThirds={true}
            />
          )
        }
        </FadeInView>
      }
        {this.level2 && this.level2.categories && 
        <FadeInView style={{flex: 0.33}}>
        {
          this.level2.categories.map(category => 
            <Button
              //activeBackgroundColor={ACTIVE_BG}
              style={menuItemStyle}
              //labelStyle={menuItemLabelStyle}
              //focused={(CategoriesMenu.backFrom && CategoriesMenu.backFrom.name === category.name)
              //  || CategoriesMenu.current == category.id}
              //focused={this.level3 == category}
              transparent={this.level3 != category}
              text={category.name}
              key={category.name}
              onPress={() => {
                this.level3 = category;
                  navigation.push(
                    "STYLD", {
                    screen: "tabs", 
                    params: {
                    screen: "Products", 
                    params: {
                    screen: 'Category',
                    params: {
                      categoryId: category.id
                    }
                    }
                  }
                  })
                  
                  categoryOnSelect && categoryOnSelect();
              }}
              twoThirds={true}
            />
          )
        }
        </FadeInView>
      }
      </View>
    )
  
  }
}

const styles = StyleSheet.create({
  container: {
    ...Device.select({
      desktop: {
        flex: 1, 
        flexDirection: 'row', 
        //minHeight: 100,
      },
        mobile: {
          paddingTop: Sizes.MARGIN,
          paddingHorizontal: Sizes.MARGIN,
        }
    })
  },
  
})