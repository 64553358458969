import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Device from '../utils/Device';

//import "firebase/performance";
firebase.initializeApp(require('../config').firebase);

const USE_EMULATORS = false;//Device.isWeb && location.hostname === "localhost" || process.env.NODE_ENV == 'development';

if (USE_EMULATORS) {
  var db = firebase.firestore();
  db.settings({
    host: "localhost:8080",
    ssl: false
  });
}
