import React from 'react';
import FadeInView from './FadeInView';
import {
  StyleSheet, Text,
} from 'react-native'
import Sizes from '../constants/Sizes';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import Button from './Button';
import px from '../utils/px';
import { observer } from 'mobx-react';
import Device from '../utils/Device';
import Styles from '../constants/Styles';

@observer
export default class LoginInvite extends React.Component {
  render() {    
    return (
      <FadeInView style={styles.loginInvite}>
        <Text style={styles.loginTitle}>
          Let's set up your STYLD account
        </Text>
        <Text style={styles.loginDescription}>
          {Device.isDesktop && '  |  '}
          Sign up for free and enjoy exclusive features, a personalized profile and special sales!
        </Text>
        <Button
          text="Sign in"
          //icon={Device.isMobile ? "sign-in" : null}
          //iconComponent={FontAwesome}
          style={styles.buyButton}
          iconStyle={styles.buyIconStyle}
          //fontSize={px(14)}
          bold//={Device.isDesktop}
          onPress={() => {
            this.props.navigation.navigate('Auth', {screen: 'AuthHome'})
          }}
          noHorizontalPadding // ={Device.isDesktop}
          //transparent={Device.isMobile}
          //underline={Device.isMobile}
          inline // ={Device.isDesktop}
          //outline={!Device.isDesktop}
        />
      </FadeInView>
    )
  }
}

const styles = StyleSheet.create({
  loginInvite: {
    //width: '100%',
    backgroundColor: '#ffffff',
    //height: px(100),
    //textAlign: 'center',
    paddingBottom: Sizes.MARGIN,
    ...Device.select({
      desktop: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }
    })
  },
  loginTitle: {
    ...Styles.text,
    
    ...Device.select({
      desktop: {
        flexDirection: 'row',
        alignSelf: 'center',
      },
      mobile: {
        textAlign: 'center',
      //marginTop: Sizes.MARGIN,
      marginVertical: Sizes.MARGIN,
      }
    }),
  },
  loginDescription: {
    //height: Sizes.LAYOUT_ITEM,
    alignItems: 'center',
    justifyContent: 'center',
    //marginVertical: Sizes.MARGIN,
    textAlign: 'center',
    ...Styles.textLight,
    //marginTop: Sizes.MARGIN,
  },
  buyIconStyle: {
    ...Device.select({
      web: {
        fontSize: px(24),
        width: px(24),
        marginRight: Sizes.MARGIN,
      },
      mobileWeb: {
        fontSize: px(24),
        width: px(24),
        marginRight: Sizes.MARGIN,
      },
      mobile: {
        marginRight: Sizes.MARGIN,
      },
      
    })
    
  },
  buyButton: {
    paddingHorizontal: px(4),
        height: Sizes.LINE_HEIGHT + 4,
    ...Device.select({
      desktop: {
        //width: px(320),
        //alignSelf: 'center'
        marginLeft: px(4),
        
      },
      mobile: {
        marginTop: Sizes.MARGIN,
        alignSelf: 'center'
      }
    })
  }
})