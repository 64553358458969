
const isNumber = val =>  (typeof val === 'number');
const isArray = val =>  Array.isArray(val);
const isObject = val =>  (typeof val === 'object' && !isArray(val));

export default function mergeAndSum(a = {}, b = {}) {
  var united = {};
  var entries = Object.entries(a).concat(Object.entries(b));
  entries.forEach(([key, val]) => {
    if (!united[key]) {
      if (isNumber(val))
        united[key] = 0;
      else if (isObject(val)) {
        united[key] = {};
      }
    }
    if (isNumber(val))
      united[key] += val;
    else if (isObject(val)) {
      for (var i in val) {
        //console.log(i, (val[i]))
        if (isNumber(val[i])) {
          if (!united[key][i]) {

            // need to find the reason for this bug with 
            // Sneakers' category sizes/colors count
            try {
            united[key][i] = 0;
            } catch(e) {

            }
          }
          try {
            united[key][i] += val[i]
          } catch(e) {

          }
        } else if (isObject(val[i])) {
          
          if (!united[key][i]) {
            united[key][i] = {};
          }
          united[key][i] = mergeAndSum(united[key][i],  val[i])
        }
      }
    }

  })
  return united;
}