import firebase from 'firebase/app';
import 'firebase/auth';
import { observable, computed, reaction, autorun } from 'mobx';
import Facebook from '../services/Facebook';
import * as GoogleSignIn from 'expo-google-sign-in'
import User from '../models/User';
import * as Updates from 'expo-updates';
import Device from '../utils/Device';
import Wishlist from '../models/Wishlist';
import Outfit from '../models/Outfit';

class Auth {
  @observable authStateInitialLoading = true;
  @observable user = null; //  ;
  @observable isLoginNow = false;
  @observable justLoggedIn = false;
  @observable justLoggedOut = false;
  @observable isLoginNowAnonymous = false;
  @observable isNewUser = false; // isNewUser is firebase' therminology
  @observable isAnonymous = null;
  @computed get isLoggedIn() {
    return !!this.user;
  }
  @computed get justSignedUp() {
    return this.isNewUser; // alias
  }
  init () {
    this.authStateInitialLoading = true;
    //firebase.auth().signOut(); return;
    firebase.auth().onAuthStateChanged( async user => {
      if (!this.isLoginNow) { // wait for login to end
        if (user) {
          //this.user = User.find(user.uid);
          //if (!user) 
            this.user = new User(user.uid);
          //this.user.get(); // prefetch
        } else {
          this.user = null;
        }
      }
      //this.isAnonymous = user && user.isAnonymous;
      this.authStateInitialLoading = false;
    })
  }
  loginWithFacebook = async () => {
    if (Device.isMobileApp) return this.loginWithFacebook_MOBILEAPP();
    if (Device.isWeb) return this.loginWithFacebook_WEB();
  }
  
  loginWithFacebook_WEB = async () => {
    this.isLoginNow = true;
    try {
      var provider = new firebase.auth.FacebookAuthProvider();
      var result = await firebase.auth().signInWithPopup(provider);
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      var {additionalUserInfo, user} = result;
      try {      
        var {isNewUser} = additionalUserInfo;
        this.isNewUser = isNewUser;
        if (isNewUser) {
          
          const name = user.displayName;
          this.user = await this.createUser(user, name);
        } else {
          this.user = new User(user.uid);
        }
        this.isLoginNow = false;
        this.justLoggedIn = true;
        this.justLoggedOut = false;
        return true;
      } catch(e) {
        this.isLoginNow = false;
        alert(e);
      }
    } catch(e) {
      // Handle Errors here.
      console.log('ERROR', e);
      var errorCode = e.code;
      var errorMessage = e.message;
      // The email of the user's account used.
      var email = e.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = e.credential;
      alert(e.message);
      return true;
      // ...
    }
    return true;
  }
  loginWithFacebook_MOBILEAPP = async () => {
    this.isLoginNow = true;

    await Facebook.initializeAsync();
    const { 
      type, 
      token,
    } = await Facebook.logInWithReadPermissionsAsync();
    
    if (type === 'success') {
      try {
        const credential = firebase.auth.FacebookAuthProvider.credential(token);
        var {additionalUserInfo, user} = await firebase.auth().signInWithCredential(credential);
        var {isNewUser} = additionalUserInfo;
        this.isNewUser = isNewUser;
        if (isNewUser) {          
          // Get the user's name using Facebook's Graph API
          Facebook.setAccessToken(token);
          const {name} = await Facebook.graph('me') ;
          this.user = await this.createUser(user, name);
        } else {
          this.user = new User(user.uid);
        }
        
        this.isLoginNow = false;
        this.justLoggedIn = true;
        this.justLoggedOut = false;
        return true;
      } catch(e) {
        this.isLoginNow = false;
        alert(e);
      }

    } else {
      // cancel
      return false;
    }
  }
  loginWithGoogle = async () => {
    if (Device.isMobileApp) return this.loginWithGoogle_MOBILEAPP();
    if (Device.isWeb) return this.loginWithGoogle_WEB();
  }
  loginWithGoogleSuccess = async () => {
    
  }
  loginWithGoogle_WEB = async () => {
    this.isLoginNow = true;
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      var result = await firebase.auth().signInWithPopup(provider);
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      var {
        user,
        additionalUserInfo,
      } = result;
      var name = user.displayName;
      try {
        var {isNewUser} = additionalUserInfo;
        this.isNewUser = isNewUser;
        if (isNewUser) {
          this.user = await this.createUser(user, name);
        } else {
          this.user = new User(user.uid);
        }
        this.isLoginNow = false;
        this.justLoggedIn = true;
        this.justLoggedOut = false;
        return true;
      } catch(e) {
        this.isLoginNow = false;
        console.log('loginWithGoogle ERROR')
        console.log(e.message)
        alert(e.message);
      }
    } catch(e) {
      // Handle Errors here.
      console.log('ERROR', e);
      var errorCode = e.code;
      var errorMessage = e.message;
      // The email of the user's account used.
      var email = e.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = e.credential;
      return true;
      // ...
    }
    return true;
  }
  loginWithGoogle_MOBILEAPP = async () => {
    this.isLoginNow = true;
    await GoogleSignIn.initAsync({
    });
    await GoogleSignIn.askForPlayServicesAsync();
    var { type, user } = await GoogleSignIn.signInAsync();
    const name = user.displayName;
    const {idToken, accessToken} = user.auth;
    // google user data is in: user.displayName; user.firstName etc
    if (type === 'success') {
      //await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const credential = firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);
      try {
        var {additionalUserInfo, user} = await firebase.auth().signInWithCredential(credential);
        var {isNewUser} = additionalUserInfo;
        this.isNewUser = isNewUser;
        if (isNewUser) {
          this.user = await this.createUser(user, name);
        } else {
          this.user = new User(user.uid);
        }
        this.isLoginNow = false;
        this.justLoggedIn = true;
        this.justLoggedOut = false;
        return true;
      } catch(e) {
        this.isLoginNow = false;
        console.log('loginWithGoogle ERROR')
        console.log(e.message)
        alert(e.message);
      }
    }
  }
  loginAsGuest = async () => {
    this.isLoginNow = true;
    this.isLoginNowAnonymous = true;
    var {user} = await firebase.auth().signInAnonymously();
    this.isNewUser = true;
    this.user = await this.createUser(user, '');
    //this.user = User.find(user.uid);
    this.isLoginNow = false;
    this.justLoggedIn = true;
    this.justLoggedOut = false;
    this.isLoginNowAnonymous = false;
    return true;
  }
  createUser = async (firebaseUser, name) => {
    var user = new User(firebaseUser.uid);
    user.name = name; // optimistic
    await (
      user.ref.set({
        id: firebaseUser.uid, 
        name,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
    );
    Wishlist.createDefaultWishlistForMe(user);
    Outfit.createDefaultOutfitForMe(user);
    if (Device.isWeb) {
      window.fbq('track', 'CompleteRegistration', {status: true});
    }
    return user;
  }
  createUserWithEmailAndPassword = async (email, password) => {
    try {
    this.isLoginNow = true;
    var response = await firebase.auth().createUserWithEmailAndPassword(email, password);
    response.user.sendEmailVerification();
    this.isNewUser = true;
    this.user = await this.createUser(response.user, '');
    this.isLoginNow = false;
    this.justLoggedIn = true;
    this.justLoggedOut = false;
    return response;
    } catch(e) {
      console.log(e.message)
      throw e;
    }
  }
  signInWithEmailAndPassword = async (email, password) => {
    try {
      this.isLoginNow = true;
      var response = await firebase.auth().signInWithEmailAndPassword(email, password);
      this.isNewUser = false;
      this.user = new User(response.user.uid);
      this.isLoginNow = false;
      this.justLoggedIn = true;
      this.justLoggedOut = false;
      return response;
      } catch(e) {
        console.log(e.message)
        throw e;
      }
  }
  sendPasswordResetEmail = (email) => {
    firebase.auth().sendPasswordResetEmail(email);
  }
  signout = () => {
    this.justLoggedIn = false;
    this.justLoggedOut = true;
    firebase.auth().signOut();
    Device.isMobileApp && Updates.reloadAsync();
  }
}

export default new Auth();