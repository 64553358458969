import "./ota.initializer"
import Font from './font.initializer'
import './firebase.initializer'
import Auth from '../services/Auth';
import { reaction } from "mobx";
import Analytics from '../services/Analytics';
import CategoriesMenu from '../models/CategoriesMenu';
import './sentry.initializer';
import './facebook-pixel.initializer';

class Initializer {
  async init() {
    await Font.init();
    Auth.init();
    CategoriesMenu.init();

    reaction(
      () => Auth.isLoggedIn,
      async flag =>  {
        if (flag) {
          Analytics.setUserId(Auth.user.id);
          
          Auth.user.fetchAndListenAllWishlists();
          Auth.user.fetchAndListenAllOutfits();
          Auth.user.fetchFollowingWishlists();
          Auth.user.fetchFollowingOutfits();
          Auth.user.fetchFollowingUsers();
          Auth.user.fetchFollowers();
          Auth.user.fetchAndListenForSocialEvents();
          Auth.user.listenForUnreadSocialCount();
        }
      }
    )
  }
}

export default new Initializer();