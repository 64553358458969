import px from "../utils/px";
import Device from "../utils/Device";

const FONT_SIZE = Device.isDesktop ? px(16) : Device.isMobileWeb ? px(16) : px(10);
const LINE_HEIGHT = FONT_SIZE + (Device.isDesktop ? 2 : 2);
const MARGIN = Device.isDesktop ? LINE_HEIGHT : LINE_HEIGHT;
const LAYOUT_ITEM = Device.isDesktop ? LINE_HEIGHT + MARGIN * 2 : LINE_HEIGHT + MARGIN * 2;

export default {
  LAYOUT_ITEM,
  BORDER_RADIUS: px(2),
  MARGIN,
  FONT: FONT_SIZE,
  TEXT: FONT_SIZE,
  FEED_ITEM_WIDTH: Device.isMobile ? (Device.width / 2 ) : px(300),
  FEED_ITEM_HEIGHT: Device.isMobile ? (Device.height / 2) : px(300),
  LINE_HEIGHT,
}