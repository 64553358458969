import Constants from "expo-constants";
const env = Constants.manifest.releaseChannel;

import firebaseConfigDev from './firebase.development';
//import firebaseConfigProd from './firebase.production';
var firebaseConfigProd = firebaseConfigDev;

export const isDev = env === __DEV__ || process.env.NODE_ENV === "development";
export const isProduction = env === 'prod' || env === 'production' || process.env.NODE_ENV === "production";
export const firebase = isDev ? firebaseConfigDev : firebaseConfigProd;
export const isLocalhost = window && window.location && window.location.hostname === 'localhost';

