const tintColor = '#222';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  TEXT: '#000000',
  TITLE: '#2b2b2b',
  TEXTINPUT_BORDER: tintColor,
  PLACEHOLDER: "#999999",
  INDICATOR: '#999999',
  LIGHT: "#b6b6b6",
  TEXT_SEMI_LIGHT: "#656565",
  BUTTON: {
    BG: tintColor,
    TEXT: '#ffffff'
  },
  danger: "#bb3333",
  SEPARATOR: "#dddddd",
};
