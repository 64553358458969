import React from 'react';
import {
  View,
  TextInput as TextInputNative,
  StyleSheet,
  Text,
} from 'react-native';
import px from '../utils/px';
import COLORS from '../constants/Colors';
import SIZES from '../constants/Sizes';
import FONTS from '../constants/Fonts';
import PropTypes from 'prop-types';
import Button from './Button';
import Sizes from '../constants/Sizes';
import FormInputLabel from './FormInputLabel';
import Colors from '../constants/Colors';
import Device from '../utils/Device';
import css from '../utils/css';

export default class TextInput extends React.Component {
  render() {
    const {
      bigFont,
      transparent,
      outline,
      withButton,
      multiline,
      buttonOnPress,
      buttonTitle,
      withLeftMargin,
      withRightMargin,
      withBottomMargin,
      label,
      fontSize,
      ...rest
    } = this.props;
    var input = (
      <View style={[
        styles.container,
        this.props.style,
        outline && styles.outline,
        withButton && styles.withButton,
        withLeftMargin && styles.withLeftMargin,
        withRightMargin && styles.withRightMargin,
        withBottomMargin && styles.withBottomMargin,
      ]}>
        
        
        <TextInputNative 
          {...rest}
          value={this.props.value || ''}
          autoCorrect={false}
          ref={ref=>this.props.onRef && this.props.onRef(ref)}
          style={[
            styles.textInput,
            this.props.editable == false && styles.disabled,
            bigFont && styles.bigFont,          
            multiline && styles.multiline,
            fontSize && {fontSize}
          ]}
          placeholderTextColor={Colors.LIGHT}
        />
        { withButton &&
          <Button
            text={buttonTitle}
            noBorder
            transparent
            style={{marginTop: px(-1)}}
            onPress={buttonOnPress}
            paddingHorizontal
          />
        }
        
      </View>
    );
    
    if (label) {
      input = (
        <React.Fragment>
          <FormInputLabel label={label} />
        {input}
        </React.Fragment>
      )
    }

    return input;
  }
}


if (Device.isWeb) {
  css(`
    input:focus {
      outline: none !important;
    }
  `)
}

const styles = StyleSheet.create({
  container: {
    height: (SIZES.LAYOUT_ITEM)- StyleSheet.hairlineWidth * 2,
    backgroundColor: '#ffffff',
    //margin: SIZES.MARGIN,
    borderRadius: (SIZES.BORDER_RADIUS),
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'solid',
    borderColor: 'transparent',
    flexDirection: 'row',
  },
  withLeftMargin: {
    marginLeft: Sizes.MARGIN,
  },
  withRightMargin: {
    marginRight: Sizes.MARGIN,
  },
  withBottomMargin: {
    marginBottom: Sizes.MARGIN,
  },
  textInput: {
    fontFamily: FONTS.MAIN,
    color: COLORS.TEXT,
    fontSize: (FONTS.SIZE),
    //width: '100%',
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
    paddingVertical: px(0),
    paddingHorizontal: Sizes.MARGIN,
  },
  label: {
    fontFamily: FONTS.MAIN_LIGHT,
    color: COLORS.TEXT,
    fontSize: (FONTS.SIZE),
    marginBottom: Sizes.MARGIN/2,
  },
  outline: {
    borderColor: COLORS.TEXTINPUT_BORDER,
  },
  disabled: {
    color: COLORS.TEXT,
  },
  bigFont: {
    fontSize: (FONTS.SIZE + 3),
  },
  multiline: {
    
  },
  withButton: {

  }
})
