import Constants from "expo-constants";
const env = Constants.manifest.releaseChannel;
const isProduction = (env && (env === 'prod' || env === 'production')) || process.env.NODE_ENV === "production";
export const projectId = "fashion-52b69";
export default {
  apiKey: "AIzaSyABngPvoMkVL1MnnsHgKnIesnREMhfb_H4",
  authDomain: "styld.app",
  databaseURL: "https://fashion-52b69.firebaseio.com",
  projectId,
  storageBucket: "fashion-52b69.appspot.com",
  messagingSenderId: "1077428072579",
  appId: "1:1077428072579:web:f1b3348953c5afbbbc6bb6",
  measurementId: isProduction ? "G-RGLRB97Q6M" : "G-E4NYZ5QPT3" 
};
