import React from 'react';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import Icon from '@expo/vector-icons/Entypo';
import Sizes from '../constants/Sizes';
import Colors from '../constants/Colors';
import px from '../utils/px';
import Fonts from '../constants/Fonts';
import Styles from '../constants/Styles';


@connectActionSheet
class SelectBox extends React.Component {
  render() {
    const {
      children,
      title,
      placeholder,
      style,
      customPreElement,
      customPostElement,
    } = this.props;
    return (
      <View style={[
        styles.selectBox,
        style,
      ]}>
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={this.onPress}
        >
        <View style={styles.selectBoxHeader}>
          {customPreElement}
          <Text style={[
            styles.selectBoxTitle,
            title && title.length && styles.selectBoxTitleText,
            (!title || !title.length) && styles.selectBoxTitlePlaceholder,
          ]}>{title || placeholder}</Text>
            <View style={styles.selectBoxHeaderButton}>
              <Icon name={"chevron-thin-down"} />
            </View>
        </View>
        </TouchableOpacity>
      </View>
    )
  }
  onPress = () => {
    const {
      titleInActionSheet,
      actions, 
      destructiveCancel,
      titleTextStyle,
      concatClose,
    } = this.props;
    var options = actions.map(a => a.title);
    if (concatClose) options = options.concat('Close');
    this.props.showActionSheetWithOptions(
      { 
        title: titleInActionSheet,
        titleTextStyle,
        //titleTextStyle: [styles.info, styles.name, styles.selectBoxTitle],
        textStyle: [styles.info],
        options,
        cancelButtonIndex: actions.length,
        destructiveButtonIndex: destructiveCancel ? actions.length : actions.findIndex(a => a.destructive),
      },
      buttonIndex => actions[buttonIndex] && actions[buttonIndex].action(),
    );
  }
}

export default SelectBox;

const styles = StyleSheet.create({
  selectBox: {
    backgroundColor: '#ffffff',
    //marginTop: Sizes.MARGIN,
  },
  selectBoxHeader: {
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: Colors.BUTTON.BG,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: (Sizes.BORDER_RADIUS),
    alignItems: 'center',
    height: (Sizes.LAYOUT_ITEM) - StyleSheet.hairlineWidth * 2,
    paddingLeft: Sizes.MARGIN
  },
  selectBoxHeaderButton: {
    paddingHorizontal: Sizes.MARGIN,
    // borderLeftColor: Colors.BUTTON.BG,
    // borderLeftWidth: StyleSheet.hairlineWidth,
  },
  selectBoxTitle: {
    ...Styles.text,
  },
  selectBoxTitlePlaceholder: {
    color: Colors.PLACEHOLDER,
  },
  selectBoxTitleText: {
    color: Colors.TEXT,
  }
})