import { computed, observable, reaction } from "mobx";

import * as firebase from 'firebase/app';
import "firebase/firestore";
import Wishlist, { WISHLIST_PRIVACY } from "./Wishlist";
import Outfit, { OUTFIT_PRIVACY } from "./Outfit";
import Product from "./Product";
import debounce from '../utils/debounce';

const REMOTE_PATH = 'users';

const db = () => firebase.firestore().collection(REMOTE_PATH);

const PUBLIC_PRODUCTS_PER_PAGE = 20;
const FOLLOWERS_PER_PAGE = 20;
const SOCIAL_EVENTS_PER_PAGE = 20;

class User {
  @observable wishlists = [];
  @observable outfits = [];
  @observable ref = null;
  @observable name = null;
  @observable username = null;
  @observable gender = null;
  @observable productsWished = null;
  @observable country = null;
  @observable email = null;
  id = null;
  lastPublicProduct = null;
  lastFollower = null;
  lastSocialEvent = null;
  @observable publicProducts = [];
  @observable publicProductsLoadMoreEnabled = true;
  @observable socialEventsLoadMoreEnabled = true;
  @observable publicProductsLoading = false;
  @observable publicWishlists = [];
  @observable publicOutfits = [];
  @observable followingWishlists = [];
  @observable followingOutfits = [];
  @observable followingUsers = [];
  @observable followers = [];
  @observable socialEvents = [];
  //lastSnapshotData = null;
  @observable followersLoading = false;
  @observable followersLoadMoreEnabled = true;
  @observable socialEventsLoading = false;
  @observable unreadSocial = 0;
  @computed get isFemale () {
    return this.gender == 'female'
  }
  @computed get isMale () {
    return this.gender == 'male'
  }

  static _all = {};
  static db = db;
  static doc(id) {
    return db().doc(id);
  }
  static get all() {
    return this._all;
  }
  static find(id) {
    return this.all[id];
  }
  constructor(uid) {
    this.id = uid;
    this.ref = this.constructor.doc(uid);
    this.ref.onSnapshot(_data => {
      if (!_data.data()) return;
      Object.entries(_data.data()).forEach(([key, val]) => this[key] = val)
      //this.lastSnapshotData = _data.data();
    });
    this.constructor.all[uid] = this;
  }
  wishlistsQuery() {
    return this.wishlistsCollection();
  }
  outfitsQuery() {
    return this.outfitsCollection();
  }
  wishlistsCollection() {
    return (
      this.ref.collection('wishlists')
    );
  }
  outfitsCollection() {
    return (
      this.ref.collection('outfits')
    );
  }
  fetchPublicWishlists = async() => {
    var query = this.wishlistsQuery();
    query = query.where('privacy', '==', WISHLIST_PRIVACY.PUBLIC);
    var snapshot = await query.get();
    var newArr = [];
    snapshot.forEach(wishlistDoc => {        
      var wishlist = Wishlist.find(wishlistDoc.id);
      if (!wishlist) {
        wishlist = (new Wishlist(wishlistDoc))
      }
      newArr.push(wishlist);
    })
    this.sortWishlistsByUpdatedAt(newArr);
    this.publicWishlists.replace(newArr);
  }
  fetchPublicOutfits = async() => {
    var query = this.outfitsQuery();
    query = query.where('privacy', '==', OUTFIT_PRIVACY.PUBLIC);
    var snapshot = await query.get();
    var newArr = [];
    snapshot.forEach(outfitDoc => {        
      var outfit = Outfit.find(outfitDoc.id);
      if (!outfit) {
        outfit = (new Outfit(outfitDoc))
      }
      newArr.push(outfit);
    })
    this.sortOutfitsByUpdatedAt(newArr);
    this.publicOutfits.replace(newArr);
  }
  socialUnreadsQuery = () => firebase.firestore().collection('socialUnreads').doc(this.id);
  listenForUnreadSocialCount() {
    var query = this.socialUnreadsQuery();
    query.onSnapshot(doc => {
      if (!doc.exists) return;
      this.unreadSocial = doc.data().count;
    })
  } 
  fetchAndListenAllWishlists() {
    var query = this.wishlistsQuery();
    query.onSnapshot(snapshot => {
      var newArr = [];
      snapshot.forEach(wishlistDoc => {        
        var wishlist = Wishlist.find(wishlistDoc.id);
        if (!wishlist) {
          wishlist = (new Wishlist(wishlistDoc))
        }
        newArr.push(wishlist);
      })
      this.sortWishlistsByUpdatedAt(newArr);
      this.wishlists.replace(newArr);
    })
  }
  fetchAndListenAllOutfits() {
    var query = this.outfitsQuery();
    query.onSnapshot(snapshot => {
      var newArr = [];
      snapshot.forEach(outfitDoc => {        
        var outfit = Outfit.find(outfitDoc.id);
        if (!outfit) {
          outfit = (new Outfit(outfitDoc))
        }
        newArr.push(outfit);
      })
      this.sortOutfitsByUpdatedAt(newArr);
      this.outfits.replace(newArr);
    })
  }
  fetchFollowingWishlists = async () => {
    var query = this.ref.collection('followingWishlists');
    var {docs} = await query.get();
    var promises = docs.map( wishlistDoc => {     
      var wishlist = Wishlist.find(wishlistDoc.id);
      if (!wishlist) {
        wishlist = Wishlist.fetchRef(wishlistDoc.data().wishlistRef)
      }
      return wishlist;
    });
    docs = await Promise.all(promises);
    this.followingWishlists.replace(docs);    
  }
  fetchFollowingOutfits = async () => {
    console.log('fetchFollowingOutfits')
    var query = this.ref.collection('followingOutfits');
    var {docs} = await query.get();
    var promises = docs.map( outfitDoc => {     
      var outfit = Outfit.find(outfitDoc.id);
      if (!outfit) {
        outfit = Outfit.fetchRef(outfitDoc.data().outfitRef)
      }
      return outfit;
    });
    docs = await Promise.all(promises);
    this.followingOutfits.replace(docs);    
  }
  fetchFollowingUsers = async () => {
    var query = this.ref.collection('followingUsers');
    var {docs} = await query.get();
    var promises = docs.map( doc => {     
      var user = User.find(doc.id);
      if (!user) {
        var {
          followedId,
        } = doc.data();
        user = new User(followedId)
      }
      return user;
    });
    docs = await Promise.all(promises);
    this.followingUsers.replace(docs);    
  }

  sortWishlistsByUpdatedAt (arr) {
    arr.sort((a, b) => {
      var dateA = new Date(a.updated_at) 
      var dateB = new Date(b.updated_at);
      return dateA - dateB;
    })
  }
  sortOutfitsByUpdatedAt (arr) {
    arr.sort((a, b) => {
      var dateA = new Date(a.updated_at) 
      var dateB = new Date(b.updated_at);
      return dateA - dateB;
    })
  }

  isProductInAnyWishlist =  (product) => {
    return Wishlist.isProductInAnyWishlist(product, this);
    //return this.wishlists.filter(wl => this.isProductInWishlist(wl, product));
  }
  isProductInAnyOutfit =  (product) => {
    return Outfit.isProductInAnyOutfit(product, this);
  }
  
  isProductInWishlist = (wishlist, product) => {
    return Wishlist.isProductInWishlist(wishlist, product, this)
  //   var isIn = wishlist.products.find(p => p.id == product.id);
  //   // var isIn = this.myWishedProducts[wishlist.id].find(p => p.id == product.id )
  //   return isIn ? wishlist : null;
  }
  isProductInOutfit = (outfit, product) => {
    return Outfit.isProductInOutfit(outfit, product, this)
  }

  // @TODO 
  // To fetch also from outfits
  // To fetch also from outfits
  // To fetch also from outfits
  // To fetch also from outfits

  fetchPublicProducts = async () => {
    var db = firebase.firestore();
    var query = (
      db.collectionGroup('wishlistProducts')
      .where('authorRef', '==', this.ref)
      .where('wishlistPrivacy', '==', WISHLIST_PRIVACY.PUBLIC)
    );
    if (this.lastPublicProduct) {
      query = query.startAfter(this.lastPublicProduct);
    }
    query = query.limit(PUBLIC_PRODUCTS_PER_PAGE);

    var snapshot = await (
      query
      .get()
    );
    
    var {docs} = snapshot; 
    var newLast = docs[docs.length-1];
    if (newLast && docs.length == PUBLIC_PRODUCTS_PER_PAGE ) { 
      this.lastPublicProduct = newLast;
    } else {
      this.publicProductsLoadMoreEnabled = false;
      this.lastPublicProduct = null;
    }

    var promises = [];
    docs.forEach(doc => promises.push(Product.fetchRef(doc.data().productRef)));
    var products = await Promise.all(promises);
    this.publicProducts.replace(this.publicProducts.concat(products));
    this.publicProductsLoading = false;
  }

  isFollowingWishlist = (wishlist) => {   
    var isIn = !!this.followingWishlists.find(wl => wl.id == wishlist.id);
    return isIn;
  }
  isFollowingOutfit = (outfit) => {   
    var isIn = !!this.followingOutfits.find(wl => wl.id == outfit.id);
    return isIn;
  }

  isFollowingUser = (user) => {   
    var userId = typeof user === 'string' ? user : user.id;
    var isIn = !!this.followingUsers.find(u => u.id == userId);
    return isIn;
  }

  followWishlist(wishlist) {
    wishlist.followersCount ++; // optimistic
    this.followingWishlists.replace(this.followingWishlists.concat(wishlist)); // optimistic
    this.ref.collection('followingWishlists').doc(wishlist.id).set({
      followerId: this.id,
      followerRef: this.ref,
      followerName: this.username || this.name,
      authorId: wishlist.authorId,
      authorRef: wishlist.authorRef,
      authorName: wishlist.authorName,
      wishlistId: wishlist.id,
      wishlistRef: wishlist.ref,
      wishlistName: wishlist.name,
    })
  }
  followOutfit(outfit) {
    outfit.followersCount ++; // optimistic
    this.followingOutfits.replace(this.followingOutfits.concat(outfit)); // optimistic
    this.ref.collection('followingOutfits').doc(outfit.id).set({
      followerId: this.id,
      followerRef: this.ref,
      followerName: this.username || this.name,
      authorId: outfit.authorId,
      authorRef: outfit.authorRef,
      authorName: outfit.authorName,
      outfitId: outfit.id,
      outfitRef: outfit.ref,
      outfitName: outfit.name,
    })
  }

  unfollowWishlist(wishlist) {
    wishlist.followersCount --; // optimistic
    //wishlist.followingWishlists.replace(wishlist.followingWishlists.filter(i=>i.id!=this.id)); // optimistic
    this.followingWishlists.replace(this.followingWishlists.filter(i=>i.id!=wishlist.id)); // optimistic
    this.ref.collection('followingWishlists').doc(wishlist.id).delete()
  }
  unfollowOutfit(outfit) {
    outfit.followersCount --; // optimistic
    this.followingOutfits.replace(this.followingOutfits.filter(i=>i.id!=outfit.id)); // optimistic
    this.ref.collection('followingOutfits').doc(outfit.id).delete()
  }

  followUser(user) {
    user.followers.replace([this].concat(user.followers)) // optimistic
    this.followingUsers.replace(this.followingUsers.concat(user)); // optimistic
    this.ref.collection('followingUsers').doc(user.id).set({
      followerId: this.id,
      followerRef: this.ref,
      followerName: this.username || this.name,
      followedId: user.id,
      followedRef: user.ref,
      followedName: user.username || user.name,
    })
  }

  unfollowUser(user) {
    user.followers.replace(user.followers.filter(i=>i.id!=this.id)); // optimistic
    this.followingUsers.replace(this.followingUsers.filter(i=>i.id!=user.id)); // optimistic
    this.ref.collection('followingUsers').doc(user.id).delete()
  }

  fetchFollowers = async () => {
    var db = firebase.firestore();
    var query = (
      db.collectionGroup('followingUsers')
        .where('followedId', '==', this.id)
    );
    if (this.lastFollower) {
      query = query.startAfter(this.lastFollower);
    }
    query = query.limit(FOLLOWERS_PER_PAGE);

    var snapshot = await (
      query
      .get()
    );
    
    var {docs} = snapshot; 
    var newLast = docs[docs.length-1];
    if (newLast && docs.length == FOLLOWERS_PER_PAGE ) { 
      this.lastFollower = newLast;
    } else {
      this.followersLoadMoreEnabled = false;
      this.lastFollower = null;
    }

    var promises = docs.map( doc => {     
      var {
        followerId,
        followerName,
      } = doc.data();
      var user = User.find(followerId);
      if (!user) {        
        user = new User(followerId)
      }
      return user;
    });
    var products = await Promise.all(promises);
    this.followers.replace(this.followers.concat(products));
    this.followersLoading = false;
  }

  socialEventsQuery = () => (
    this.ref.collection('socialEventsFeed')
      .orderBy('created_at','desc')
  );

  _fetchSocialEvents = async () => {
    this.socialEventsLoading = true;
    var query = this.socialEventsQuery();
    if (this.lastSocialEvent) {
      query = query.startAfter(this.lastSocialEvent);
    }
    query = query.limit(SOCIAL_EVENTS_PER_PAGE);

    var snapshot = await (
      query
      .get()
    );
    
    var {docs} = snapshot; 
    var newLast = docs[docs.length-1];
    if (newLast && docs.length == SOCIAL_EVENTS_PER_PAGE ) { 
      this.lastSocialEvent = newLast;
    } else {
      this.socialEventsLoadMoreEnabled = false;
      this.lastSocialEvent = null;
    }

    this.socialEventsLoading = false;
    return docs;
  }

  fetchSocialEvents = async () => {
    var docs = await this._fetchSocialEvents();
    this.socialEvents.replace(this.socialEvents.concat(docs));    
  }
  listenForSocialEvents = async () => {
    var query = this.socialEventsQuery().limit(1);
    query.onSnapshot(snapshot => {
      var newDoc = snapshot.docs[0];
      if (!newDoc) return;
      if (this.socialEvents.find(se => se.id == newDoc.id)) return;
      this.socialEvents.replace([newDoc].concat(this.socialEvents));
    })
  }
  fetchAndListenForSocialEvents = async () => {
    await this.fetchSocialEvents();
    this.listenForSocialEvents();
  }

  refreshSocialEvents = async () => {
    this.lastSocialEvent = null;
    var docs = await this._fetchSocialEvents();
    this.socialEvents.replace(docs);    
  }

  hasWishlist = wishlistId => this.wishlists.find(w => w.id == wishlistId);
  hasOutfit = outfitId => this.outfits.find(w => w.id == outfitId);

  debouncedUpdate = (pendingChange) => {
    this.pendingChange = pendingChange;
    Object.entries(pendingChange).forEach(([key, val]) => this[key] = val)
    this._debouncedUpdate();
  }
  _debouncedUpdate = debounce(()=>{
    this.ref.update(this.pendingChange);
  }, 1000)

  resetUnreadSocial = () => {
    this.unreadSocial = 0; // optimistic
    var query = this.socialUnreadsQuery();
    query.set({
      count: 0,
    }, {merge: true})
  }
}

export default User;