import React, { useRef } from 'react';
import {  
  View,
  TouchableOpacity, 
  StyleSheet, 
  Text,
} from 'react-native';
import Animated from 'react-native-reanimated';
import Sizes from '../constants/Sizes';
import Device from '../utils/Device';
import px from '../utils/px';
import Fonts from '../constants/Fonts';
import ProductsScreen from '../screens/ProductsScreen';
import useOutsideClick from '../utils/useOutsideClick';
import UnreadNumber from '../components/UnreadNumber';
import Styles from '../constants/Styles';
import Button from '../components/Button';
import UserIcon from '@expo/vector-icons/FontAwesome';

// USING TIMEOUT TO PREVENT FAST PRODUCTS MENU REOPENING
// AFTER CLICKING OUTSIDE THE MENU ON THE PRODUCTS BUTTON
var justClosedProductsTimeout;

export default function TabBar({ state, descriptors, navigation, position }) {
  const [productsIsOpen, openProducts] = React.useState(false);
  const wrapperRef = useRef(null);
  return (
    <View style={styles.container}>
      <WebHeader navigation={navigation} />
    <View style={styles.tabBar}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        if (route.name == 'Search') return null;

        const onPress = () => {
          
          // CAUSED BIG WEIRD BUG WITH SEARCH PARAMS WEIRD CACHE
          // const event = navigation.emit({
          //   type: 'tabPress',
          //   target: route.key,
          //   canPreventDefault: true,
          // });

          // this comment was the main reason we are using custom component
          //if (!isFocused && !event.defaultPrevented) {
          if (route.name != 'Products') { 
            //var screen;
            //if (route.name == 'Social') screen = 'Social';
            //if (route.name == 'Home') screen = 'Home';
            navigation.navigate(route.name, {
              screen: route.name
            }
            );
          } else {
            if (justClosedProductsTimeout) return;
            
            openProducts(!productsIsOpen);
            useOutsideClick(wrapperRef, ()=>{
              justClosedProductsTimeout = setTimeout(()=>justClosedProductsTimeout=null, 1000);
              openProducts(false);
            });

          }
          //}
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        // const inputRange = state.routes.map((_, i) => i);
        // const opacity = Animated.interpolate(position, {
        //   inputRange,
        //   outputRange: inputRange.map(i => (i === index ? 1 : 0)),
        // });

        return (
          <TouchableOpacity
            key={label}
            activeOpacity={0.8}
            accessibilityRole="button"
            accessibilityStates={isFocused ? ['selected'] : []}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[
              styles.button,
              isFocused && styles.buttonActive,
            ]}
            accessibilityRole="link"
            href={route.name.toLowerCase()}
          >
            <Animated.Text 
              //style={{ opacity }} 
              style={styles.buttonText}
            >
              {label.toUpperCase()}
              
            </Animated.Text>
            {label == 'Social' && <UnreadNumber />}
          </TouchableOpacity>
        );
      })}
    </View>
    <div 
      ref={wrapperRef}
    >
    <TouchableOpacity style={[
      styles.blackOverlay,
      productsIsOpen && {opacity: 0.6, height: 1000}
    ]} onPress={()=>openProducts(false)} />
      <View 
        style={[
          styles.productsMenuWrapper,
          productsIsOpen && styles.productsIsOpen,
          !productsIsOpen && styles.productsIsHidden,
        ]}
      >
        <View 
        style={[
          styles.productsMenu,
        ]}
        >
          <ProductsScreen 
            navigation={navigation}
            categoryOnSelect={()=>{
              openProducts(false);
            }}
          />
        </View>
      </View>
      
    </div>
    </View>
  );
}

const WebHeader = ({navigation}) => (
  <View style={styles.headerContainer}>
    <View style={styles.headerContent}>
    <Button
      icon="search1"
      transparent
      whiteText
      square
      style={styles.searchButton}
      iconStyle={styles.searchIcon}
      onPress={() => navigation.navigate('Search', {screen: 'Search'})}
      //text={'Search'}
    />
    <Text style={styles.logoText} 
      accessibilityRole="link"
      href="https://styld.app"
      onPress={() => navigation.navigate('Home', {screen: 'Home'})}
    >STYLD</Text>
    <Button
      icon="star-o"
      iconComponent={UserIcon}
      transparent
      whiteText
      square
      style={styles.searchButton}
      iconStyle={styles.userIcon}
      onPress={() => navigation.navigate('Wishlists', {screen: 'Wishlists'})}
      //text={'Search'}
    />
    <Button
      icon="user-o"
      iconComponent={UserIcon}
      transparent
      whiteText
      square
      style={styles.searchButton}
      iconStyle={styles.userIcon}
      onPress={() => navigation.navigate('Me', {screen: 'Me'})}
      //text={'Search'}
    />
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: { 
    position: 'relative',
    width: '100%',
    zIndex: 1000,
    backgroundColor: 'transparent',
  },
  tabBar: { 
    width: Math.max(Device.width / 2 , px(1200)),
    flexDirection: 'row' ,
    height: Sizes.LAYOUT_ITEM,
    //flex: 1,
    alignSelf: 'center',
  },
  productsMenuWrapper: {
    width: '100%',
    top: Sizes.LAYOUT_ITEM * 2,
    left: 0,
    backgroundColor: '#ffffff',
    position: 'absolute',
    //boxShadow: '0 2px 2px rgba(0,0,0,0.1)',
    paddingVertical: Sizes.MARGIN,
  },
  productsMenu: {
    width: px(910), //Math.max(Device.width / 2 , px(600)),
    //minHeight: Sizes.LAYOUT_ITEM, 
    flex: 1,
    alignSelf: 'center',
    
    
  },
  productsIsHidden: {
    height: 1,
    paddingVertical: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  productsIsOpen: {
    opacity: 1,
    height: 'auto',
  },
  button: {
    flex: 1,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonActive: {
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
  },
  buttonText: {
    ...Styles.text,
    letterSpacing: 1,
  },
  blackOverlay: {
    width: '100%', 
    height: 0, 
    backgroundColor: '#000000', // 'rgba(0,0,0,0.9)', 
    opacity: 0,
    position: 'absolute',
    top: Sizes.LAYOUT_ITEM * 2,
    left: 0,
  },
  headerContainer: {
    height: Sizes.LAYOUT_ITEM,
    width: '100%',
    //flex: 1,
    backgroundColor: '#000000',
    
  },
  logoText: {
    fontFamily: Fonts.LOGO_BOLD,
    fontSize: px(38),
    color: '#FFFFFF',
    lineHeight: px(40),
    flex: 1,
    textAlign: 'center',
    marginRight: px(-Sizes.LAYOUT_ITEM)
  },
  headerContent: {
    width: px(1200),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    flexDirection: 'row',
  },
  searchIcon: {
    fontSize: px(24),
  },
  userIcon: {
    fontSize: px(22),
  },
  wishlistsIcon: {
    fontSize: px(22),
  },
  searchButton: {
    //marginRight: px(-Sizes.LAYOUT_ITEM)
  }
})