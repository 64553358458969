import Device from "../utils/Device";
import px from "../utils/px";
import Colors from "./Colors";
import Sizes from "./Sizes";

export default {
  MAIN: 'Lato',
  MAIN_BOLD: 'Lato Bold',
  MAIN_LIGHT: 'Lato Light',
  LOGO_BOLD: 'Playfair Bold',
  SIZE: Sizes.TEXT,
  TITLE_SIZE: Sizes.TEXT,
  BUTTON: {
    SIZE: Sizes.TEXT
  },
  COLOR: Colors.TEXT,
}