import { observable } from "mobx";
import firebase from 'firebase/app';
import "firebase/firestore";
import Category from './Category'

class CategoriesMenuModel {
  @observable loading = true;
  @observable data = true;
  @observable backCategories = [];
  @observable backFrom = null;
  @observable current = null;

  async getGender(id) {
    var ref = firebase.firestore().collection(id);
    var gender = await ref.get();
    var genderCategories = []
    gender.forEach(category => {
      var data = category.data();
      var cat = {
        name: category.id,
      };
      var categories = data.categories.map(sub => new Category({
        ...sub,
        gender: id.toLowerCase(),
        parent: cat,
      }))
      cat.categories = categories;
      genderCategories.push(cat);
    })

    return ({
      name: id,
      categories: genderCategories,
    });
  }

  async init() {  
    var DATA = {};
    var female = await this.getGender('Female');
    var male = await this.getGender('Male');
    DATA.categories = [ female, male ];
    
    this.data = DATA;
    this.current = this.data;
    this.loading = false;
  
    return true;
  }

  findKeywordCategory = (keyword, gender) => {
    //console.log(this.category)
    var _category = null;
    this.data.categories.forEach(genderCategory => {
      if (gender.toLowerCase() == genderCategory.name.toLowerCase() )
      genderCategory.categories.forEach(subCategory => {
        subCategory.categories.forEach(category => {
          if (category.keywords && category.keywords.find(k=>k == keyword))
            _category = category;
        })
      })
    });
    return _category;
  }
}

export default new CategoriesMenuModel();