import Fonts from "./Fonts";
import px from "../utils/px";
import Colors from "./Colors";
import Sizes from "./Sizes";
import Device from "../utils/Device";
import Constants from "expo-constants"

const productThumbBrandText = {
  fontFamily: Fonts.MAIN,
  fontSize: Fonts.SIZE,
  color: Colors.TITLE,
  lineHeight: Sizes.LINE_HEIGHT,
};

const text = {
  fontFamily: Fonts.MAIN,
  fontSize: Fonts.SIZE,
  color: Colors.TEXT,
  lineHeight: Sizes.LINE_HEIGHT,
}

const textLight = {
  fontFamily: Fonts.MAIN_LIGHT,
  fontSize: Fonts.SIZE,
  color: Colors.TEXT,
  lineHeight: Sizes.LINE_HEIGHT,
}

const textLightFaded = {
  fontFamily: Fonts.MAIN_LIGHT,
  fontSize: Fonts.SIZE,
  color: Colors.LIGHT,
  lineHeight: Sizes.LINE_HEIGHT,
}

const br = {
  height: Sizes.MARGIN,
}
const brHalf = {
  height: Sizes.MARGIN / 2,
}


const safeArea = {
  flex: 1,
  paddingTop: Constants.statusBarHeight,
}

export default {
  productThumbBrandText,
  text,
  textLight,
  textLightFaded,
  safeArea,
  br,
  brHalf,
}