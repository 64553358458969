import React, { useState, useEffect } from 'react';
import { Animated } from 'react-native';
import Device from '../utils/Device';

const useNativeDriver = Device.isMobileApp;

const FadeInView = props => {
  const [fadeAnim] = useState(new Animated.Value(0));
  useEffect(()=>{
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: props.duration || 300,
      useNativeDriver,
    }).start();
  })
  return (
    <Animated.View 
      {...props}
      useNativeDriver={useNativeDriver}
      style={[
        props.style,
        {
        opacity: fadeAnim, 
        // transform: [{
        //   scale: fadeAnim.interpolate({
        //     inputRange: [0, 0.2, 1],
        //     outputRange: [0.97, 0.975, 1]
        //   }),
        // }],
      }]}
    />
  );
};

export default FadeInView;